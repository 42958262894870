<script lang="ts">
	import type { InventoryType, SearchField } from 'utility/search-fields'

	import InventoryTypeAutocomplete from './InventoryTypeAutocomplete.svelte'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let inventoryType: InventoryType | null = null
	export let disabled: boolean = false
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<InventoryTypeAutocomplete
		bind:inventoryType
		disabled={inEditMode || disabled}
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</InventoryTypeAutocomplete>
</EditModeControls>
