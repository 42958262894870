import type { AppContext } from 'types/common'
import component from './Scanner.svelte'
import { type ScannerOptions, standardProfile } from '@isoftdata/svelte-scanner-configuration'
import { getObject } from '@isoftdata/utility-storage'

export default function ({ stateRouter, mediator }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration.scanner',
		route: 'scanner',
		template: {
			svelte: true,
			component,
		},
		async resolve(_, _parameters) {
			/*
			const companyDefaultConfiguration = await mediator.call('emitToServer', 'check global setting', {
				name: 'Barcode Scanner Options',
				category: 'Options',
				defaultValue: JSON.stringify(standardProfile), // standard profile is the default to fall back on
				settingType: 'Important Configuration',
			})*/

			const companyDefaultConfiguration = standardProfile
			const localStorageBarcodeOptions = getObject<ScannerOptions>(localStorage, 'barcodeScannerOptions')
			const barcodeScannerOptions = localStorageBarcodeOptions ?? companyDefaultConfiguration
			return Promise.resolve({ barcodeScannerOptions })
		},
	})
}
