export default {
    "name": "InventoryOptionsConfig",
    "kind": "HoudiniQuery",
    "hash": "eb824482499e8d68842f62804b278820e3fc85d0eb270e0781bf6630c67ca87d",

    "raw": `query InventoryOptionsConfig($inventoryTypeId: [Int!], $manufacturerId: [Int!], $modelId: [Int!], $categoryName: [String!]) {
  inventoryOptions(
    inventoryTypeId: $inventoryTypeId
    manufacturerId: $manufacturerId
    modelId: $modelId
    categoryName: $categoryName
  ) {
    ...InventoryOptionDataForConfig
    id
  }
}

fragment InventoryOptionDataForConfig on InventoryOption {
  id
  name
  rank
  required
  public
  dataType
  choices(suppressFixedChoices: false, suppressQueryChoices: true) {
    id
    isDefault: default
    label
    rank
  }
  manufacturer {
    id
    name
  }
  model {
    id
    name
    active
  }
  category {
    id
    name
    description
  }
  inventoryType {
    id
    name
    setId
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
  defaultChoice
  choiceQuery
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryOptions": {
                "type": "InventoryOption",
                "keyRaw": "inventoryOptions(categoryName: $categoryName, inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "required": {
                            "type": "Boolean",
                            "keyRaw": "required",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices(suppressFixedChoices: false, suppressQueryChoices: true)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "isDefault": {
                                        "type": "Boolean",
                                        "keyRaw": "isDefault",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "category": {
                            "type": "Category",
                            "keyRaw": "category",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "setId": {
                                        "type": "Int",
                                        "keyRaw": "setId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel1": {
                                        "type": "String",
                                        "keyRaw": "typeLabel1",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel2": {
                                        "type": "String",
                                        "keyRaw": "typeLabel2",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel3": {
                                        "type": "String",
                                        "keyRaw": "typeLabel3",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel4": {
                                        "type": "String",
                                        "keyRaw": "typeLabel4",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultChoice": {
                            "type": "String",
                            "keyRaw": "defaultChoice",
                            "nullable": true,
                            "visible": true
                        },

                        "choiceQuery": {
                            "type": "String",
                            "keyRaw": "choiceQuery",
                            "nullable": true,
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "InventoryOptionDataForConfig": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "inventoryTypeId": "Int",
            "manufacturerId": "Int",
            "modelId": "Int",
            "categoryName": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4156732fadbcc62d4b72bf5f7dbb42fe18c91116350c3dc98f08095497efff50";