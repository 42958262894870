import svelteComponent from './InventoryType.svelte'
import type { AppContext } from 'types/common'
import { graphql } from '$houdini'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'

const inventoryTypesQuery = graphql(`
	query InventoryTypesFull($filter: InventoryTypeFilter, $storeId: UInt!) {
		inventoryTypes(filter: $filter) {
			active
			id
			name
			setId
			vehicle
			taxCode
		}
		taxCodes(storeId: $storeId) {
			code
			description
			name
		}
	}
`)

export default function ({ stateRouter }: AppContext) {
	const stateName = 'app.configuration.inventory-type'

	stateRouter.addState({
		name: stateName,
		route: 'inventory-type',
		querystringParameters: ['showInactive'],
		defaultParameters: { showInactive: 'False' },
		defaultChild: 'custom-question',
		template: {
			svelte: true,
			component: svelteComponent,
		},
		async resolve(_data, { inventoryTypeId, ...params }) {
			const showInactive = stringToBoolean(params.showInactive)
			const session = getSession()

			const { data } = await inventoryTypesQuery.fetch({
				variables: {
					filter: {
						active: showInactive ? undefined : true,
					},
					storeId: session.currentStore,
				},
			})

			return {
				taxCodes: data?.taxCodes ?? [],
				inventoryTypes: data?.inventoryTypes ?? [],
				showInactive,
				selectedInventoryTypeId: inventoryTypeId ? parseInt(inventoryTypeId, 10) || undefined : undefined,
			}
		},
	})
}
