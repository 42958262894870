import type { AppContext } from 'types/common'

import component from './Results.svelte'
import { graphql } from '$houdini'
import { type CustomerBalanceStatus } from 'utility/customer-helper'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.customer-search.results',
		route: 'results',
		querystringParameters: ['pageNumber', 'perPageCount', 'keyword'],
		defaultParameters: {
			pageNumber: '1',
			perPageCount: '10',
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = parseInt(parameters.perPageCount, 10) || 10

			const { data } = await customerSearchQuery.fetch({
				variables: {
					filter: {
						keyword: parameters.keyword,
					},
					pagination: {
						pageNumber,
						pageSize,
					},
				},
			})

			if (!data) {
				throw new Error('Failed to load customers')
			}

			const customers = data.customers.items.map(customer => {
				let customerBalanceStatus: CustomerBalanceStatus
				const customerBalance = Number(customer.balance)
				const customerAccountLimit = Number(customer.accountLimit)

				if (customerBalance > 0 && customerBalance <= customerAccountLimit) {
					customerBalanceStatus = 'OWE_AND_UNDER_LIMIT'
				} else if (customerBalance > 0 && customerBalance >= customerAccountLimit) {
					customerBalanceStatus = 'OWE_AND_OVER_LIMIT'
				} else if (customerBalance === 0) {
					customerBalanceStatus = 'ZERO_BALANCE'
				} else if (customerBalance < 0) {
					customerBalanceStatus = 'CREDIT_BALANCE'
				} else if (!customer.active) {
					customerBalanceStatus = 'INACTIVE'
				} else {
					customerBalanceStatus = 'NO_STATUS'
				}

				return {
					...customer,
					balanceStatus: customerBalanceStatus,
				}
			})

			return {
				customers,
				perPageCount: data.customers.pageInfo.pageSize.toString(),
				currentPageNumber: data.customers.pageInfo.pageNumber,
				totalItemsCount: data.customers.totalItems,
			}
		},
	})
}

const customerSearchQuery = graphql(`
	query CustomerSearch($pagination: PaginationOptions, $filter: CustomerFilter) {
		customers(pagination: $pagination, filter: $filter) {
			items {
				id
				active
				contactName
				balance
				accountLimit
				dateEntered
				phoneNumber
				companyName
				store {
					code
				}
				faxNumber
				mobilePhoneNumber
				type
				billingAddress {
					address1
					city
					state
					zip
					country
				}
				email
				webAddress
				defaultTerm {
					name
				}
				salesPerson {
					name
				}
				defaultPaymentMethod {
					name
				}
				optionValues {
					option {
						label
						dataType
						showInCustomerList
					}
					value
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
			}
			totalItems
		}
	}
`)
