export default {
    "name": "InventoryOptionDataForConfig",
    "kind": "HoudiniFragment",
    "hash": "931e47eac8ce2ae476cd42c3152015d7e47c8ddebc2bfcadda8149660571427c",

    "raw": `fragment InventoryOptionDataForConfig on InventoryOption {
  id
  name
  rank
  required
  public
  dataType
  choices(suppressFixedChoices: false, suppressQueryChoices: true) {
    id
    isDefault: default
    label
    rank
  }
  manufacturer {
    id
    name
  }
  model {
    id
    name
    active
  }
  category {
    id
    name
    description
  }
  inventoryType {
    id
    name
    setId
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
  defaultChoice
  choiceQuery
  __typename
}
`,

    "rootType": "InventoryOption",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "Int",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "rank": {
                "type": "Int",
                "keyRaw": "rank",
                "visible": true
            },

            "required": {
                "type": "Boolean",
                "keyRaw": "required",
                "visible": true
            },

            "public": {
                "type": "Boolean",
                "keyRaw": "public",
                "visible": true
            },

            "dataType": {
                "type": "DataTypeEnum",
                "keyRaw": "dataType",
                "visible": true
            },

            "choices": {
                "type": "InventoryOptionChoice",
                "keyRaw": "choices(suppressFixedChoices: false, suppressQueryChoices: true)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "isDefault": {
                            "type": "Boolean",
                            "keyRaw": "isDefault",
                            "visible": true
                        },

                        "label": {
                            "type": "String",
                            "keyRaw": "label",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "manufacturer": {
                "type": "Manufacturer",
                "keyRaw": "manufacturer",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "model": {
                "type": "Model",
                "keyRaw": "model",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "category": {
                "type": "Category",
                "keyRaw": "category",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true,
                            "nullable": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "inventoryType": {
                "type": "InventoryType",
                "keyRaw": "inventoryType",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel1": {
                            "type": "String",
                            "keyRaw": "typeLabel1",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel2": {
                            "type": "String",
                            "keyRaw": "typeLabel2",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel3": {
                            "type": "String",
                            "keyRaw": "typeLabel3",
                            "nullable": true,
                            "visible": true
                        },

                        "typeLabel4": {
                            "type": "String",
                            "keyRaw": "typeLabel4",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "defaultChoice": {
                "type": "String",
                "keyRaw": "defaultChoice",
                "nullable": true,
                "visible": true
            },

            "choiceQuery": {
                "type": "String",
                "keyRaw": "choiceQuery",
                "nullable": true,
                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=db32cd9f3c609c2bf0a55eb6a7660c8b758b6724c81a8a28dd98ee40c485fe9d";