<script lang="ts">
	import Icon from '@isoftdata/svelte-icon'
	import type { ComponentProps } from 'svelte'

	export let icon: ComponentProps<Icon>['icon'] | ComponentProps<Icon>
	export let title

	$: iconProps = typeof icon === 'string' ? { icon } : icon
</script>

<div class="card-header">
	<div class="d-flex flex-wrap justify-content-between">
		<div class="d-inline-block">
			<h4 class="text-ellipsis d-inline">
				<Icon
					{...iconProps}
					fixedWidth
				></Icon>
				{title}
				<slot name="title"></slot>
			</h4>
		</div>
		<div class="d-flex flex-wrap">
			<slot name="right"></slot>
		</div>
	</div>
	<slot></slot>
</div>
