export default {
    "name": "CustomerOptions",
    "kind": "HoudiniQuery",
    "hash": "144e973dbb56a70da8f7b3e5a3a7cd7c1fac3d2079720d7cbb9498a5d0d7dd8b",

    "raw": `query CustomerOptions {
  customerOptions {
    id
    label
    dataType
    showInCustomerList
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "customerOptions": {
                "type": "CustomerOption",
                "keyRaw": "customerOptions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "label": {
                            "type": "String",
                            "keyRaw": "label",
                            "visible": true
                        },

                        "dataType": {
                            "type": "CustomerOptionDataType",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "showInCustomerList": {
                            "type": "Boolean",
                            "keyRaw": "showInCustomerList",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=df44637e262fc0c0cf40ad0bb9a7f4ff8bd4c0a02012efffe4fc8a5592b9e73e";