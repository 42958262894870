export default {
    "name": "TestChoiceQuery",
    "kind": "HoudiniQuery",
    "hash": "77d1bd6cc64aaf9da325db88b2240f7e4a13885021b3d7c3fc3d3db3b938a0a1",

    "raw": `query TestChoiceQuery($query: String!) {
  testSelectQuery(query: $query)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "testSelectQuery": {
                "type": "Boolean",
                "keyRaw": "testSelectQuery(query: $query)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "query": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1eb52dde9e6b96ef62149447c34a998425779b1aa90accf1519a8f035665da8a";