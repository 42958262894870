export default {
    "name": "InventoryTypesFull",
    "kind": "HoudiniQuery",
    "hash": "e268232d7ca5aa6932748b132f36a17113f48e5a98e7847a60cf46208085b7f8",

    "raw": `query InventoryTypesFull($filter: InventoryTypeFilter, $storeId: UInt!) {
  inventoryTypes(filter: $filter) {
    active
    id
    name
    setId
    vehicle
    taxCode
  }
  taxCodes(storeId: $storeId) {
    code
    description
    name
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventoryTypes": {
                "type": "InventoryType",
                "keyRaw": "inventoryTypes(filter: $filter)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "setId": {
                            "type": "Int",
                            "keyRaw": "setId",
                            "nullable": true,
                            "visible": true
                        },

                        "vehicle": {
                            "type": "Boolean",
                            "keyRaw": "vehicle",
                            "visible": true
                        },

                        "taxCode": {
                            "type": "String",
                            "keyRaw": "taxCode",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "taxCodes": {
                "type": "TaxCode",
                "keyRaw": "taxCodes(storeId: $storeId)",

                "selection": {
                    "fields": {
                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "InventoryTypeFilter",
            "storeId": "UInt"
        },

        "types": {
            "InventoryTypeFilter": {
                "active": "Boolean",
                "vehicle": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=11b0ebcc7ab6dba926eac5c304a37e7a409a60eeb56e166eed41c2c8b593406b";