import { validate } from 'vinidator'

/*
Objects	Barcode Contents	Notes
Inventory	*[inventoryid]*	inventoryid must be less than 12 characters
UPC	*[raw_UPC]*	raw_UPC must be numeric only, exactly 12 characters
Serialized Inventory	*[inventoryid]-[inventoryserialid]* or *D[inventoryserialid]*	the D form is only for database version 307 or above
Location	*%[locationid]*
-Sales Order	*S[storeid]-[salesorderid]*
-Purchase Order	*P[storeid]-[purchaseorderid]*
-Transfer Order	*T[transferorderid]*
-Work Order Job	*J[jobid]*
-ITrack User	*U[userid]*
OEM Number	*[any non-empty sequence]*	Catchall for anything that didn't match
*/
/*
SCAN_GUESSERS = {
	INVENTORY_ID: {
		test: function(scan_test) { return /^[0-9]{1,11}$/.exec(scan_test) },
		result: 'inventory'
	},
	INVENTORY_UPC: {
		test: function(scan_test) { return /[0-9]{12,}/.exec(scan_test) },
		result: 'inventory'
	},
	SERIALIZED_INVENTORY: {
		test: function(scan_test) { return (/^[0-9]+\-[0-9]+$/.exec(scan_test) || /^[dD][0-9]+$/.exec(scan_test) ) },
		result: 'inventory_serial'
	},
	SALES_ORDER_ID: {
		test: function(scan_test) { return /^[sS][0-9]+\-[0-9]+$/.exec(scan_test) },
		result: 'sales_order'
	},
	JOB_ID: {
		test: function(scan_test) { return /^[jJ][0-9]+$/.exec(scan_test) },
		result: 'job_id'
	},
	PURCHASE_ORDER_ID: {
		test: function(scan_test) { return /^[pP][0-9]+\-[0-9]+$/.exec(scan_test) },
		result: 'purchase_order'
	},
	LOCATION_ID: {
		test: function(scan_test) { return /^%[0-9]+$/.exec(scan_test) },
		result: 'location'
	},
	LOCATION_STRING: {
		test: function(scan_test) { return scan_test.toString().length > 0 },
		result: 'location'
	},
	OEM_NUMBER: {
		test: function(scan_test) { return scan_test.toString().length > 0 },
		result: 'inventory'
	}
}

function scanGuesser(scan_test, guesses) {
	var i, test

	if (typeof guesses === 'undefined') {
		guesses = ['INVENTORY_ID', 'SERIALIZED_INVENTORY', 'INVENTORY_UPC', 'SALES_ORDER_ID', 'JOB_ID', 'PURCHASE_ORDER_ID',
			'LOCATION_ID', 'LOCATION_STRING']
	}

	for (i = 0; i < guesses.length; i++) {
		if (SCAN_GUESSERS[guesses[i]]) {
			test = SCAN_GUESSERS[guesses[i]].test
			if (test(scan_test)) {
				return SCAN_GUESSERS[guesses[i]].result
			}
		}
	}
	return false
}
*/

export function getBarcodeType(barcode: string) {
	const letterCodeMap = new Map([
		['%', 'LOCATION'],
		['S', 'SALES_ORDER'],
		['P', 'PURCHASE_ORDER'],
		['T', 'TRANSFER_ORDER'],
		['J', 'JOB'],
		['U', 'USER_ID'],
		['D', 'SERIAL_ID'],
	])

	/*
	If an "I" exists at the start of the scanned VIN barcode,
    it means it's an Import. But that's not part of the
    VIN standard. Thus it needs to be stripped first.
    */
	const vin = barcode.toUpperCase().charAt(0) === 'I' ? barcode.substring(1) : barcode
	if (validate(vin).isValid) {
		return {
			type: 'VIN',
			value: vin,
		}
	} else if (/^[%TtJjUuDd][0-9]+$/.exec(barcode) || /^[%SsPp][0-9]+-[0-9]+$/.exec(barcode)) {
		const letterCode = barcode.charAt(0).toUpperCase()
		barcode = barcode.substring(1)
		return {
			type: letterCodeMap.get(letterCode),
			value: barcode,
		}
	} else if (/^[0-9]{12,}$/.exec(barcode)) {
		return {
			type: 'UPC',
			value: barcode,
		}
	} else if (/^[0-9]{1,11}$/.exec(barcode)) {
		return {
			type: 'INVENTORY_ID',
			value: barcode,
		}
	} else if (/^[0-9]+\\-[0-9]+$/.exec(barcode)) {
		barcode = barcode.split('-')[1]

		return {
			type: 'SERIAL_ID',
			value: barcode,
		}
	}

	return {
		type: 'OTHER',
		value: barcode,
	}
}
