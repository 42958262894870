import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/TestChoiceQuery'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class TestChoiceQueryStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "TestChoiceQueryStore",
			variables: true,
		})
	}
}

export async function load_TestChoiceQuery(params) {
	await initClient()

	const store = new TestChoiceQueryStore()

	await store.fetch(params)

	return {
		TestChoiceQuery: store,
	}
}
