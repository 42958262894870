<script lang="ts">
	import Icon from '@isoftdata/svelte-icon'

	export let value: boolean
</script>

<Icon
	fixedWidth
	aria-hidden="true"
	icon={value ? 'check' : 'xmark-large'}
	class={value ? 'text-success' : 'text-danger'}
></Icon>
<span class="sr-only">{value ? 'Yes' : 'No'}</span>
