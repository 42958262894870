<script lang="ts">
	import ScannerConfiguration, { type ScannerOptions } from '@isoftdata/svelte-scanner-configuration'
	import { setObject } from '@isoftdata/utility-storage'

	export let barcodeScannerOptions: ScannerOptions
</script>

<small>Note: This configuration only affects this device.</small>
<hr />
<ScannerConfiguration
	preamble={barcodeScannerOptions.preamble}
	postamble={barcodeScannerOptions.postamble}
	enableHoneywell={barcodeScannerOptions.enableHoneywell}
	on:change={e => {
		setObject(localStorage, 'barcodeScannerOptions', e.detail)
	}}
/>
