<script lang="ts">
	import type { Model, InventoryType, Manufacturer, SearchField } from 'utility/search-fields'
	import type { Type } from 'components/ModelAutocomplete.svelte'

	import Pin from 'components/Pin.svelte'
	import EditModeControls from 'components/EditModeControls.svelte'
	import ModelAutocomplete from './ModelAutocomplete.svelte'

	export let searchField: SearchField
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let model: Model | null = null
	export let manufacturer: Manufacturer | null
	export let type: Type // no default, require that they pass it
	export let disabled: boolean = false
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<ModelAutocomplete
		{type}
		{manufacturer}
		bind:model
		disabled={disabled || inEditMode}
		on:change
		{...$$restProps}
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</ModelAutocomplete>
</EditModeControls>
