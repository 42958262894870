import svelteComponent from './CustomQuestion.svelte'
import type { AppContext } from 'types/common'

import { graphql } from '$houdini'

const dataTypesMap = new Map([
	['TEXT', 'Text'],
	['AUTOSUGGEST_TEXT', 'Autosuggest Text'],
	['CHOICE', 'Choice'],
	['NUMBER', 'Number'],
	['CURRENCY', 'Currency'],
	['DATE', 'Date'],
	['TIME', 'Time'],
	['DATETIME', 'Datetime'],
	['BOOLEAN', 'Boolean'],
])

const inventoryOptionsQuery = graphql(`
	query InventoryOptionsConfig($inventoryTypeId: [Int!], $manufacturerId: [Int!], $modelId: [Int!], $categoryName: [String!]) {
		inventoryOptions(inventoryTypeId: $inventoryTypeId, manufacturerId: $manufacturerId, modelId: $modelId, categoryName: $categoryName) {
			...InventoryOptionDataForConfig
		}
	}
`)

graphql(`
	fragment InventoryOptionDataForConfig on InventoryOption {
		id
		name
		rank
		required
		public
		dataType
		choices(suppressFixedChoices: false, suppressQueryChoices: true) {
			id
			isDefault: default
			label
			rank
		}
		manufacturer {
			id
			name
		}
		model {
			id
			name
			active
		}
		category {
			id
			name
			description
		}
		inventoryType {
			id
			name
			setId
			typeLabel1
			typeLabel2
			typeLabel3
			typeLabel4
		}
		defaultChoice
		choiceQuery
	}
`)

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration.inventory-type.custom-question',
		route: 'custom-question',
		querystringParameters: ['inventoryTypeId'],
		template: {
			svelte: true,
			component: svelteComponent,
		},
		async resolve(_data, { inventoryTypeId }) {
			const [inventoryOptions] = await Promise.all([
				inventoryOptionsQuery.fetch({
					variables: {
						inventoryTypeId: inventoryTypeId ? [parseInt(inventoryTypeId, 10)] : null,
					},
				}),
			])

			return {
				selectedInventoryTypeId: inventoryTypeId ? parseInt(inventoryTypeId, 10) || undefined : undefined,
				questions: (inventoryOptions.data?.inventoryOptions ?? []).map(question => ({
					...question,
					hasChoices: !!question.choiceQuery || question.choices.length > 0,
				})),
				dataTypesMap,
			}
		},
	})
}
