export default {
    "name": "UpdateInventoryOption",
    "kind": "HoudiniMutation",
    "hash": "0d2e1801d67eff9e0535bec6a0c09da99018a0d234759081d48d7c660db683f5",

    "raw": `mutation UpdateInventoryOption($input: InventoryOptionUpdate!) {
  updateInventoryOption(input: $input) {
    ...InventoryOptionDataForConfig
    id
  }
}

fragment InventoryOptionDataForConfig on InventoryOption {
  id
  name
  rank
  required
  public
  dataType
  choices(suppressFixedChoices: false, suppressQueryChoices: true) {
    id
    isDefault: default
    label
    rank
  }
  manufacturer {
    id
    name
  }
  model {
    id
    name
    active
  }
  category {
    id
    name
    description
  }
  inventoryType {
    id
    name
    setId
    typeLabel1
    typeLabel2
    typeLabel3
    typeLabel4
  }
  defaultChoice
  choiceQuery
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateInventoryOption": {
                "type": "InventoryOption",
                "keyRaw": "updateInventoryOption(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "Int",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "rank": {
                            "type": "Int",
                            "keyRaw": "rank",
                            "visible": true
                        },

                        "required": {
                            "type": "Boolean",
                            "keyRaw": "required",
                            "visible": true
                        },

                        "public": {
                            "type": "Boolean",
                            "keyRaw": "public",
                            "visible": true
                        },

                        "dataType": {
                            "type": "DataTypeEnum",
                            "keyRaw": "dataType",
                            "visible": true
                        },

                        "choices": {
                            "type": "InventoryOptionChoice",
                            "keyRaw": "choices(suppressFixedChoices: false, suppressQueryChoices: true)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "isDefault": {
                                        "type": "Boolean",
                                        "keyRaw": "isDefault",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "manufacturer": {
                            "type": "Manufacturer",
                            "keyRaw": "manufacturer",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "model": {
                            "type": "Model",
                            "keyRaw": "model",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "category": {
                            "type": "Category",
                            "keyRaw": "category",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "inventoryType": {
                            "type": "InventoryType",
                            "keyRaw": "inventoryType",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "setId": {
                                        "type": "Int",
                                        "keyRaw": "setId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel1": {
                                        "type": "String",
                                        "keyRaw": "typeLabel1",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel2": {
                                        "type": "String",
                                        "keyRaw": "typeLabel2",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel3": {
                                        "type": "String",
                                        "keyRaw": "typeLabel3",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "typeLabel4": {
                                        "type": "String",
                                        "keyRaw": "typeLabel4",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultChoice": {
                            "type": "String",
                            "keyRaw": "defaultChoice",
                            "nullable": true,
                            "visible": true
                        },

                        "choiceQuery": {
                            "type": "String",
                            "keyRaw": "choiceQuery",
                            "nullable": true,
                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "InventoryOptionDataForConfig": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "InventoryOptionUpdate"
        },

        "types": {
            "InventoryOptionChoiceInput": {
                "action": "ActionEnum",
                "id": "Int",
                "isDefault": "Boolean",
                "label": "String",
                "rank": "Int"
            },

            "InventoryOptionUpdate": {
                "categoryName": "String",
                "choiceQuery": "String",
                "choices": "InventoryOptionChoiceInput",
                "dataType": "DataTypeEnum",
                "id": "Int",
                "inventoryTypeId": "Int",
                "manufacturerId": "Int",
                "modelId": "Int",
                "name": "String",
                "public": "Boolean",
                "rank": "Int",
                "required": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c88dff8fba41f7888bc355e09afdd4a6ef3edfdc34bc826819a2c31a697b9877";