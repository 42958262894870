import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/CustomerSalesOrders'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class CustomerSalesOrdersStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "CustomerSalesOrdersStore",
			variables: true,
		})
	}
}

export async function load_CustomerSalesOrders(params) {
	await initClient()

	const store = new CustomerSalesOrdersStore()

	await store.fetch(params)

	return {
		CustomerSalesOrders: store,
	}
}
