export default {
    "name": "InventoryDocumentHistory",
    "kind": "HoudiniQuery",
    "hash": "3a26d0eea7f75f0b7cdc2251cd7a83ec84cd00479696401224115c4339e84bb1",

    "raw": `query InventoryDocumentHistory($inventoryId: UInt!, $filter: InventoryHistoryFilter, $orderBy: [InventoryHistorySort!], $pagination: PaginationOptions) {
  inventory(id: $inventoryId) {
    history(filter: $filter, orderBy: $orderBy, pagination: $pagination) {
      items {
        documentTypeName
        documentNumber
        documentDate
        unitPrice
        documentQuantity
        affectedQuantity
        sourceName
        destinationName
        destinationAddress
        documentNotes
        lineDescription
        timeEntered
        document {
          ... on SalesOrderLine {
            salesOrder {
              void
              internalComments
              documentType {
                affectsInventory
                id
              }
              id
            }
            id
          }
          ... on PurchaseOrderLine {
            purchaseOrder {
              internalComments
              void
              id
            }
            id
          }
          ... on TransferOrderLine {
            transferOrder {
              void
              id
            }
            id
          }
          ... on JobPart {
            job {
              workOrder {
                void
                id
              }
              id
            }
            id
          }
          ... on WorkOrderMasterPart {
            workOrder {
              void
              id
            }
            id
          }
          __typename
        }
      }
      totalItems
    }
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "inventory": {
                "type": "Inventory",
                "keyRaw": "inventory(id: $inventoryId)",

                "selection": {
                    "fields": {
                        "history": {
                            "type": "InventoryHistoryList",
                            "keyRaw": "history(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                            "selection": {
                                "fields": {
                                    "items": {
                                        "type": "InventoryHistory",
                                        "keyRaw": "items",

                                        "selection": {
                                            "fields": {
                                                "documentTypeName": {
                                                    "type": "String",
                                                    "keyRaw": "documentTypeName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "documentNumber": {
                                                    "type": "String",
                                                    "keyRaw": "documentNumber",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "documentDate": {
                                                    "type": "Date",
                                                    "keyRaw": "documentDate",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "unitPrice": {
                                                    "type": "Decimal2",
                                                    "keyRaw": "unitPrice",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "documentQuantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "documentQuantity",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "affectedQuantity": {
                                                    "type": "Decimal6",
                                                    "keyRaw": "affectedQuantity",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "sourceName": {
                                                    "type": "String",
                                                    "keyRaw": "sourceName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "destinationName": {
                                                    "type": "String",
                                                    "keyRaw": "destinationName",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "destinationAddress": {
                                                    "type": "String",
                                                    "keyRaw": "destinationAddress",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "documentNotes": {
                                                    "type": "String",
                                                    "keyRaw": "documentNotes",
                                                    "visible": true
                                                },

                                                "lineDescription": {
                                                    "type": "String",
                                                    "keyRaw": "lineDescription",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "timeEntered": {
                                                    "type": "DateTime",
                                                    "keyRaw": "timeEntered",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "document": {
                                                    "type": "InventoryHistoryDocument",
                                                    "keyRaw": "document",
                                                    "nullable": true,

                                                    "selection": {
                                                        "abstractFields": {
                                                            "fields": {
                                                                "SalesOrderLine": {
                                                                    "salesOrder": {
                                                                        "type": "SalesOrder",
                                                                        "keyRaw": "salesOrder",

                                                                        "selection": {
                                                                            "fields": {
                                                                                "void": {
                                                                                    "type": "Boolean",
                                                                                    "keyRaw": "void",
                                                                                    "visible": true
                                                                                },

                                                                                "internalComments": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "internalComments",
                                                                                    "visible": true
                                                                                },

                                                                                "documentType": {
                                                                                    "type": "SalesOrderDocument",
                                                                                    "keyRaw": "documentType",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "affectsInventory": {
                                                                                                "type": "Boolean",
                                                                                                "keyRaw": "affectsInventory",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "UInt",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "id": {
                                                                                    "type": "ID",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "PurchaseOrderLine": {
                                                                    "purchaseOrder": {
                                                                        "type": "PurchaseOrder",
                                                                        "keyRaw": "purchaseOrder",

                                                                        "selection": {
                                                                            "fields": {
                                                                                "internalComments": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "internalComments",
                                                                                    "visible": true
                                                                                },

                                                                                "void": {
                                                                                    "type": "Boolean",
                                                                                    "keyRaw": "void",
                                                                                    "visible": true
                                                                                },

                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "TransferOrderLine": {
                                                                    "transferOrder": {
                                                                        "type": "TransferOrder",
                                                                        "keyRaw": "transferOrder",

                                                                        "selection": {
                                                                            "fields": {
                                                                                "void": {
                                                                                    "type": "Boolean",
                                                                                    "keyRaw": "void",
                                                                                    "visible": true
                                                                                },

                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "JobPart": {
                                                                    "job": {
                                                                        "type": "Job",
                                                                        "keyRaw": "job",

                                                                        "selection": {
                                                                            "fields": {
                                                                                "workOrder": {
                                                                                    "type": "WorkOrder",
                                                                                    "keyRaw": "workOrder",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "void": {
                                                                                                "type": "Boolean",
                                                                                                "keyRaw": "void",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "UInt",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                },

                                                                "WorkOrderMasterPart": {
                                                                    "workOrder": {
                                                                        "type": "WorkOrder",
                                                                        "keyRaw": "workOrder",

                                                                        "selection": {
                                                                            "fields": {
                                                                                "void": {
                                                                                    "type": "Boolean",
                                                                                    "keyRaw": "void",
                                                                                    "visible": true
                                                                                },

                                                                                "id": {
                                                                                    "type": "UInt",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "visible": true
                                                                    },

                                                                    "id": {
                                                                        "type": "UInt",
                                                                        "keyRaw": "id",
                                                                        "visible": true
                                                                    },

                                                                    "__typename": {
                                                                        "type": "String",
                                                                        "keyRaw": "__typename",
                                                                        "visible": true
                                                                    }
                                                                }
                                                            },

                                                            "typeMap": {}
                                                        },

                                                        "fields": {
                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "abstract": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "inventoryId": "UInt",
            "filter": "InventoryHistoryFilter",
            "orderBy": "InventoryHistorySort",
            "pagination": "PaginationOptions"
        },

        "types": {
            "DateFilter": {
                "eq": "Date",
                "gt": "Date",
                "gte": "Date",
                "lt": "Date",
                "lte": "Date",
                "ne": "Date"
            },

            "PurchaseOrderFilter": {
                "doneReceiving": "Boolean",
                "pricesFinalized": "Boolean",
                "storeId": "UInt",
                "void": "Boolean"
            },

            "PurchaseOrderLineFilter": {
                "purchaseOrderFilter": "PurchaseOrderFilter"
            },

            "Decimal2Filter": {
                "eq": "Decimal2",
                "gt": "Decimal2",
                "gte": "Decimal2",
                "lt": "Decimal2",
                "lte": "Decimal2"
            },

            "SalesOrderDocumentTypeFilter": {
                "affectsInventory": "Boolean",
                "id": "UInt"
            },

            "SalesOrderFilter": {
                "date": "DateFilter",
                "documentTypeFilter": "SalesOrderDocumentTypeFilter",
                "finalized": "Boolean",
                "storeId": "UInt",
                "void": "Boolean"
            },

            "SalesOrderLineFilter": {
                "price": "Decimal2Filter",
                "salesOrderFilter": "SalesOrderFilter"
            },

            "TransferOrderFilter": {
                "doneReceiving": "Boolean",
                "void": "Boolean"
            },

            "TransferOrderLineFilter": {
                "transferOrderFilter": "TransferOrderFilter"
            },

            "WorkOrderFilter": {
                "isEstimate": "Boolean",
                "void": "Boolean"
            },

            "InventoryHistoryFilter": {
                "documentDate": "DateFilter",
                "includeManualAdjustments": "Boolean",
                "purchaseOrderLineFilter": "PurchaseOrderLineFilter",
                "salesOrderLineFilter": "SalesOrderLineFilter",
                "transferOrderLineFilter": "TransferOrderLineFilter",
                "workOrderFilter": "WorkOrderFilter"
            },

            "PaginationOptions": {
                "pageNumber": "Int",
                "pageSize": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=a3dfd7100e44726f4ff3e0d1675722d2b46433905dc2d404e3973c97fdbd38c2";