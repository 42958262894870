<script lang="ts">
	import type { InventoryType } from 'utility/search-fields'

	import { onMount } from 'svelte'
	import { loadInventoryTypes } from 'utility/search-fields'
	import Autocomplete from '@isoftdata/svelte-autocomplete'

	export let inventoryTypeList: InventoryType[] = []
	export let inventoryType: InventoryType | null = null
	export let vehicle: boolean | undefined = undefined //Defines if the loaded list of inventory types should be filtered to just vehicle types

	onMount(async () => {
		inventoryTypeList = await loadInventoryTypes({ vehicle })
	})
</script>

<Autocomplete
	label="{vehicle ? 'Unit' : 'Inventory'} Type"
	placeholder="-- Select {vehicle ? 'a Unit' : 'an Inventory'} Type --"
	options={inventoryTypeList}
	emptyValue={null}
	bind:value={inventoryType}
	getLabel={option => (option ? `${option?.id} - ${option?.name}` : '')}
	on:change
	{...$$restProps}
>
	<svelte:fragment slot="hint">
		<slot name="hint"></slot>
	</svelte:fragment>
</Autocomplete>
