export default {
    "name": "SetDefaultReportPrinterMutation",
    "kind": "HoudiniMutation",
    "hash": "13ca294082973a1593eb3013f2c87d66111beb35beb2a8e75a65a66bedbb5814",

    "raw": `mutation SetDefaultReportPrinterMutation($input: SetDefaultReportPrinter!) {
  setDefaultReportPrinter(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setDefaultReportPrinter": {
                "type": "ReportPrinter",
                "keyRaw": "setDefaultReportPrinter(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "SetDefaultReportPrinter"
        },

        "types": {
            "SetDefaultReportPrinter": {
                "printer": "String",
                "reportType": "String",
                "storeId": "Int",
                "userId": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=5ad709c0a3cb7223875e7416b0157761afe3ea840e0ff7d41f438bc648cfc9cf";