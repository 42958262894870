import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/AddressSearch'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class AddressSearchStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "AddressSearchStore",
			variables: true,
		})
	}
}

export async function load_AddressSearch(params) {
	await initClient()

	const store = new AddressSearchStore()

	await store.fetch(params)

	return {
		AddressSearch: store,
	}
}
