import type { AppContext } from 'types/common'
import component from './CustomerSearch.svelte'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.customer-search',
		route: 'customer-search',
		template: {
			svelte: true,
			component,
		},
		async resolve(_stateRouterData, _parameters) {
			return Promise.resolve()
		},
	})
}
