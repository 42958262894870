<script lang="ts">
	import type { InventoryType } from 'utility/search-fields'
	import type { InventoryOptionsConfig$result, InventoryOptionUpdate, NewInventoryOption, NewInventoryOptionChoice } from '$houdini'
	import type { Merge, WritableDeep } from 'type-fest'
	type CustomQuestion = WritableDeep<
		Merge<
			Omit<InventoryOptionsConfig$result['inventoryOptions'][0], ' $fragments'>,
			{
				id: number | null
				choices: Array<
					Merge<
						InventoryOptionsConfig$result['inventoryOptions'][0]['choices'][number],
						{
							id: number | null
							isDefault: boolean
							action?: 'CREATE' | 'UPDATE' | 'DELETE'
						}
					>
				>
				' $fragments'?: unknown
			}
		>
	> //Make the id nullable for new(unsaved) questions

	type SavedCustomQuestion = Omit<InventoryOptionsConfig$result['inventoryOptions'][0], ' $fragments'> & {
		' $fragments'?: unknown
	}

	//Svelte components
	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import { SqlEditor } from '@isoftdata/svelte-code-editor'
	import CategoryAutocomplete from 'components/CategoryAutocomplete.svelte'
	import ManufacturerAutocomplete from 'components/ManufacturerAutocomplete.svelte'
	import ModelAutocomplete from 'components/ModelAutocomplete.svelte'

	//Utility functions
	import { klona } from 'klona'
	import { graphql } from '$houdini'
	import { createEventDispatcher, tick } from 'svelte'
	import { Table, Td } from '@isoftdata/svelte-table'
	import QaInput from 'components/QaInput.svelte'
	import { slide } from 'svelte/transition'
	import BooleanIndicator from 'components/BooleanIndicator.svelte'
	import { getEventChecked } from '@isoftdata/browser-event'

	const defaultNewCustomQuestion: CustomQuestion = Object.freeze({
		id: null,
		name: '',
		inventoryType: null,
		manufacturer: null,
		model: null,
		category: null,
		dataType: 'TEXT',
		public: false,
		required: false,
		choiceQuery: '',
		rank: 0,
		choices: [],
		defaultChoice: null,
	})

	export let inventoryTypes: Array<InventoryType>
	export let selectedInventoryTypeId: number | null = null
	export let dataTypesMap: Map<string, string>

	const findInventoryType = (inventoryTypeId: number) => inventoryTypes.find(inventoryType => inventoryTypeId === inventoryType.id) ?? null

	//None of these vars are exported as we want the consumer to interface with the modal via the add/edit methods that are exported
	let showModal = false
	let customQuestion: CustomQuestion = klona({
		...defaultNewCustomQuestion,
		inventoryType: selectedInventoryTypeId ? findInventoryType(selectedInventoryTypeId) : null,
	})
	let isNew = false
	let isSaving = false
	let addingChoiceQuery = false
	let choiceQueryTestResult: {
		success: boolean
		error: string | null
	} | null = null
	let isTestingChoiceQuery = false
	let sqlEditor: SqlEditor | undefined = undefined
	let errorMessage = ''
	let queryChanged = false

	$: queryIsSafe = !queryChanged || (choiceQueryTestResult?.success ?? false)

	const dispatch = createEventDispatcher<{
		questionSaved: SavedCustomQuestion
	}>()

	export async function add(inventoryTypeId?: number) {
		if (inventoryTypeId) {
			customQuestion = klona({
				...defaultNewCustomQuestion,
				inventoryType: findInventoryType(inventoryTypeId),
			})
		} else {
			customQuestion = klona(defaultNewCustomQuestion)
		}

		reset()
		isNew = true
		await tick()
		document.querySelector<HTMLInputElement>('#custom-question-name')?.focus()
	}

	export async function edit(editQuestion: CustomQuestion) {
		customQuestion = klona(editQuestion)
		reset()
		await tick()
		document.querySelector<HTMLInputElement>('#custom-question-name')?.focus()
	}

	function reset() {
		showModal = true
		isNew = false
		addingChoiceQuery = false
		choiceQueryTestResult = null
		isTestingChoiceQuery = false
		errorMessage = ''
		queryChanged = false
	}

	function formatNewQuestionForSave(question: CustomQuestion): NewInventoryOption {
		return {
			dataType: question.dataType,
			name: question.name,
			inventoryTypeId: question.inventoryType?.id ?? null,
			manufacturerId: question.manufacturer?.id ?? null,
			public: question.public,
			required: question.required,
			categoryName: question.category?.name ?? null,
			choiceQuery: question.choiceQuery || null,
			modelId: question.model?.id ?? null,
			rank: question.rank,
		}
	}

	function formatUpdateQuestionForSave(question: CustomQuestion & { id: number }): InventoryOptionUpdate {
		return {
			id: question.id,
			...formatNewQuestionForSave(question),
			choices: question.choices.map(choice => ({
				...formatNewChoiceForSave(choice),
				id: choice.id,
				action: choice.action ?? (choice.id ? 'UPDATE' : 'CREATE'),
			})),
		}
	}

	function formatNewChoiceForSave(choice: NewInventoryOptionChoice): NewInventoryOptionChoice {
		return {
			label: choice.label,
			isDefault: choice.isDefault,
			rank: choice.rank,
		}
	}

	export async function save() {
		isSaving = true
		if (customQuestion.choiceQuery && !queryIsSafe) {
			await testChoiceQuery()
			if (!queryIsSafe) {
				isSaving = false
				return
			}
		}

		try {
			let savedQuestion: SavedCustomQuestion | null = null
			if (!customQuestion.id) {
				const { data } = await createMutation.mutate({
					input: {
						...formatNewQuestionForSave(customQuestion),
						choices: customQuestion.choices.map(formatNewChoiceForSave),
					},
				})
				savedQuestion = data?.createInventoryOption ?? null
			} else {
				const { data } = await updateMutation.mutate({ input: formatUpdateQuestionForSave({ ...customQuestion, id: customQuestion.id }) })
				savedQuestion = data?.updateInventoryOption ?? null
			}
			if (savedQuestion) {
				dispatch('questionSaved', savedQuestion)
			}
			showModal = false
		} catch (err: any) {
			errorMessage = err.message
		}

		isSaving = false
	}

	async function addChoice(focus = true) {
		customQuestion.choices.push({
			id: null,
			label: customQuestion.dataType === 'BOOLEAN' ? 'False' : '',
			isDefault: !customQuestion.choices.length,
			action: 'CREATE',
			rank: customQuestion.choices.length,
		})
		customQuestion.choices = customQuestion.choices

		if (focus) {
			await tick()
			document.querySelector<HTMLInputElement>(`#choice-${customQuestion.choices.length - 1} input, #choice-${customQuestion.choices.length - 1} select`)?.focus()
		}
	}

	async function addChoiceQuery() {
		addingChoiceQuery = true
		customQuestion.choiceQuery = ''
		await tick()
		sqlEditor?.focus()
	}

	async function testChoiceQuery(query: string | null = customQuestion.choiceQuery) {
		if (!query) {
			choiceQueryTestResult = { success: false, error: 'Query is empty' }
			return
		}

		try {
			isTestingChoiceQuery = true
			const { data } = await testChoiceQueryQuery.fetch({ variables: { query } })
			choiceQueryTestResult = { success: data?.testSelectQuery ?? false, error: null }
		} catch (err: any) {
			choiceQueryTestResult = { success: false, error: err.extensions?.originalError?.sqlMessage ?? err.message }
		} finally {
			isTestingChoiceQuery = false
		}
	}

	function removeChoice(index: number) {
		let action = customQuestion.choices[index].action

		if (action === 'CREATE') {
			customQuestion.choices.splice(index, 1)
		} else {
			customQuestion.choices[index] = {
				...customQuestion.choices[index],
				action: action === 'DELETE' ? 'UPDATE' : 'DELETE',
			}
			customQuestion.choices = customQuestion.choices
		}

		customQuestion.choices = customQuestion.choices
	}
	//#region GraphQL

	const testChoiceQueryQuery = graphql(`
		query TestChoiceQuery($query: String!) {
			testSelectQuery(query: $query)
		}
	`)

	const updateMutation = graphql(`
		mutation UpdateInventoryOption($input: InventoryOptionUpdate!) {
			updateInventoryOption(input: $input) {
				...InventoryOptionDataForConfig
			}
		}
	`)

	const createMutation = graphql(`
		mutation CreateInventoryOption($input: NewInventoryOption) {
			createInventoryOption(input: $input) {
				...InventoryOptionDataForConfig
			}
		}
	`)
	//#endregion
</script>

<Modal
	bind:show={showModal}
	confirmButtonText={queryIsSafe ? 'Save' : 'Test Query & Save'}
	confirmButtonIsLoading={isSaving}
	confirmButtonDisabled={!!choiceQueryTestResult && !choiceQueryTestResult.success}
	title={isNew ? 'Add Custom Question' : 'Edit Custom Question'}
	backdropClickCancels={false}
	on:confirm={save}
	on:close={() => (showModal = false)}
>
	<fieldset
		id="customQuestionFieldSet"
		disabled={isSaving}
	>
		<div class="form-row align-items-end">
			<div class="col-12 col-sm-6">
				<Input
					required
					label="Name"
					id="custom-question-name"
					validation={{
						value: customQuestion.name,
					}}
					bind:value={customQuestion.name}
				/>
			</div>
			<div class="col-12 col-sm-6">
				<Autocomplete
					label="Inventory Type"
					placeholder="All Inventory Types"
					options={inventoryTypes}
					emptyValue={null}
					bind:value={customQuestion.inventoryType}
					getLabel={option => (option ? `${option?.id} - ${option?.name}` : '')}
				>
					<svelte:fragment slot="hint">
						<slot name="hint"></slot>
					</svelte:fragment>
				</Autocomplete>
			</div>
			<div class="col-12 col-sm-6">
				<ManufacturerAutocomplete
					inventoryType={customQuestion.inventoryType}
					type="PART"
					bind:manufacturer={customQuestion.manufacturer}
				/>
			</div>
			<div class="col-12 col-sm-6">
				<ModelAutocomplete
					type="PART"
					inventoryType={customQuestion.inventoryType}
					manufacturer={customQuestion.manufacturer}
					bind:model={customQuestion.model}
				/>
			</div>
			<div class="col-12 col-sm-6">
				<CategoryAutocomplete
					inventoryType={customQuestion.inventoryType}
					bind:category={customQuestion.category}
				/>
			</div>
			<div class="col-12 col-sm-6">
				<Select
					label="Data Type"
					bind:value={customQuestion.dataType}
				>
					{#each [...dataTypesMap] as [key, text]}
						<option value={key}>{text}</option>
					{/each}
				</Select>
			</div>
			<div class="col-auto">
				<Checkbox
					label="Public"
					bind:checked={customQuestion.public}
				/>
				<Checkbox
					label="Required"
					bind:checked={customQuestion.required}
				/>
			</div>
		</div>
		<div class="card mt-3">
			<h5 class="card-header">Choices & Default Value</h5>
			<div class="card-body p-2">
				<Table
					columns={[
						{
							name: 'Choice',
							property: 'label',
						},
						{
							name: 'Default',
							property: 'isDefault',
							align: 'center',
							width: '1rem',
						},
						{
							name: 'Delete',
							property: 'action',
							align: 'center',
							width: '1rem',
						},
					]}
					rows={customQuestion.choices}
				>
					<svelte:fragment
						slot="body"
						let:rows
					>
						<!-- Deliberately not-keyed each block -->
						{#each rows as row}
							<tr id="choice-{row.originalIndex}">
								<Td property="label">
									<QaInput
										id={row.id}
										name={customQuestion.name}
										showLabel={false}
										dataType={customQuestion.dataType === 'CHOICE' || customQuestion.dataType === 'AUTOSUGGEST_TEXT' ? 'TEXT' : customQuestion.dataType}
										value={row.label}
										on:change={event => {
											let value = ''
											if (typeof event.detail === 'number') {
												value = event.detail.toString()
											} else if (typeof event.detail === 'boolean') {
												value = event.detail ? 'True' : 'False'
											} else {
												value = event.detail ?? ''
											}
											customQuestion.choices[row.originalIndex].label = value
										}}
									></QaInput>
								</Td>
								<Td property="isDefault">
									<input
										type="checkbox"
										checked={row.isDefault}
										on:change={event => {
											const checked = getEventChecked(event)
											customQuestion.choices.forEach((_choice, index) => {
												customQuestion.choices[index].isDefault = checked ? index === row.originalIndex : false
											})
										}}
									/>
								</Td>
								<Td property="action">
									<Button
										outline
										size="sm"
										color="danger"
										iconClass={row.action === 'DELETE' ? 'trash-undo' : 'trash'}
										on:click={() => removeChoice(row.originalIndex)}
									></Button>
								</Td>
							</tr>
						{:else}
							<td
								colspan="3"
								class="text-center">No Choices. Click "New Choice" to add one.</td
							>
						{/each}
					</svelte:fragment>
				</Table>
				{#if (addingChoiceQuery || customQuestion.choiceQuery) && customQuestion.choiceQuery !== null}
					<hr />
					<h6>Choice Query</h6>
					<SqlEditor
						placeholder="eg. SELECT `column1` AS `label`, `column2` AS `id` FROM table"
						readonly={isSaving || isTestingChoiceQuery}
						bind:this={sqlEditor}
						bind:value={customQuestion.choiceQuery}
						on:change={() => {
							choiceQueryTestResult = null
							queryChanged = true
						}}
					></SqlEditor>
				{/if}
			</div>
			<div class="card-footer d-flex justify-content-between">
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					on:click={() => addChoice()}>New Choice</Button
				>
				{#if customQuestion.choiceQuery === null && !addingChoiceQuery}
					<Button
						outline
						size="sm"
						color="success"
						iconClass="plus"
						on:click={() => addChoiceQuery()}>Add Choice Query</Button
					>
				{:else if addingChoiceQuery || customQuestion.choiceQuery}
					<Button
						outline
						size="sm"
						iconClass="play"
						on:click={() => testChoiceQuery(customQuestion.choiceQuery)}
						disabled={!customQuestion.choiceQuery || isTestingChoiceQuery}>Test Query</Button
					>
				{/if}
			</div>
		</div>
		{#if typeof choiceQueryTestResult?.success === 'boolean' || errorMessage}
			<div
				class="alert mt-2"
				class:alert-danger={!choiceQueryTestResult?.success || errorMessage}
				class:alert-success={choiceQueryTestResult?.success}
				transition:slide={{ duration: 100 }}
			>
				<h6 class="alert-header">
					{#if choiceQueryTestResult}
						<BooleanIndicator value={choiceQueryTestResult?.success} /> Test {choiceQueryTestResult?.success ? 'Passed' : 'Failed'}
					{:else}
						Error Saving Question
					{/if}
				</h6>
				{choiceQueryTestResult?.error ?? errorMessage}
			</div>
		{/if}
	</fieldset>
</Modal>
