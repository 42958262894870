import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/CustomerOptions'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class CustomerOptionsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "CustomerOptionsStore",
			variables: false,
		})
	}
}

export async function load_CustomerOptions(params) {
	await initClient()

	const store = new CustomerOptionsStore()

	await store.fetch(params)

	return {
		CustomerOptions: store,
	}
}
