<script lang="ts">
	import type { Category, InventoryType } from 'utility/search-fields'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import { loadCategories } from 'utility/search-fields'

	export let category: Category | null = null
	export let inventoryType: InventoryType | null
	export let disabled: boolean = false
	export let vehicle: boolean = false

	let categoryList: Promise<Category[]> | Category[] = []

	async function loadCategoryListAndSet(inventoryType: InventoryType | null) {
		if (!inventoryType) {
			categoryList = []
			category = null
		} else {
			categoryList = loadCategories(inventoryType?.id)
			categoryList.then(categories => {
				categoryList = categories
				//If we have a selected category but after loading the new list, it's not in the list, then we need to the clear the selection
				if (category && !categories.find(categoryListItem => categoryListItem.id === category?.id)) {
					category = null
				}
			})
		}
	}

	$: loadCategoryListAndSet(inventoryType)
</script>

<Autocomplete
	label="Category"
	placeholder={inventoryType ? '-- Select a Category --' : `Choose ${vehicle ? 'a unit' : 'an inventory'} type first`}
	disabled={disabled || !inventoryType}
	options={categoryList}
	emptyValue={null}
	bind:value={category}
	getLabel={option => option?.name ?? ''}
	{...$$restProps}
	on:change
>
	<svelte:fragment slot="hint">
		<slot name="hint"></slot>
	</svelte:fragment>
</Autocomplete>
