<script lang="ts">
	import type { SvelteAsr } from 'types/common'
	import { hasPermission } from 'utility/permission'

	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import Input from '@isoftdata/svelte-input'
	import Popover from '@isoftdata/svelte-popover'

	export let asr: SvelteAsr
	let searchKeyword: string = ''
</script>

<div class="card">
	<div class="card-header">
		<div class="d-flex justify-content-between align-items-end">
			<div class="h4 mb-0">
				<div>
					<Icon
						icon="user"
						class="mr-2"
					/><span class="d-none d-sm-inline-block">Customer Search</span>
				</div>
			</div>
			<div>
				{#if hasPermission('CUSTOMERS_CAN_ADD')}
					<Button
						outline
						size="sm"
						color="success"
						iconClass="plus"
						href={asr.makePath('app.customer')}
					>
						New Customer
					</Button>
				{/if}
			</div>
		</div>
	</div>
	<div class="card-body">
		<form
			id="search-field-form"
			on:submit|preventDefault={() => {
				asr.go('app.customer-search.results', { keyword: searchKeyword })
			}}
		>
			<Input
				size=""
				showLabel={false}
				labelParentClass="mb-0"
				placeholder="Keyword Search"
				bind:value={searchKeyword}
			>
				<svelte:fragment slot="append">
					<!-- Had to manually apply the rounded border radius, there are two "component" in the append slot here (button and popover) -->
					<Popover
						outline
						autoUpdate
						color="secondary"
						iconClass="info-circle"
						style="border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem;"
					>
						<svelte:fragment slot="popover">
							<h6 class="popover-header">Search Filters By:</h6>
							<div class="popover-body">
								<ul class="mb-0 pl-3">
									<li class="text-nowrap">Company Name</li>
									<li class="text-nowrap">Contact Name</li>
									<li class="text-nowrap">Phone Number</li>
								</ul>
							</div>
						</svelte:fragment>
					</Popover>
				</svelte:fragment>
			</Input>
		</form>
	</div>

	<div class="card-footer d-flex justify-content-end border-bottom">
		<Button
			type="submit"
			form="search-field-form"
			iconClass="search"
			size="sm"
		>
			Search Customers
		</Button>
	</div>
</div>

<uiView></uiView>
