<script lang="ts">
	import type { Column } from '@isoftdata/svelte-table'
	import type { SvelteAsr } from 'types/common'
	import { perPageCounts, type PerPageCount } from 'utility/search-fields'
	import { formatCustomerBalance, type CustomerSearchResult, customerBalanceStatusMap } from 'utility/customer-helper'

	import Select from '@isoftdata/svelte-select'
	import Table, { Td } from '@isoftdata/svelte-table'

	export let asr: SvelteAsr
	export let customers: CustomerSearchResult[]
	export let perPageCount: PerPageCount
	export let currentPageNumber = 1
	export let totalItemsCount: number | undefined = undefined

	// TODO:
	// - add title on all the columns, could get it from the desktop
	// - add column hiding and column resizing

	const columns: Column<CustomerSearchResult>[] = [
		{
			name: 'ID',
			property: 'id',
			title: '',
			align: 'right',
			sortType: 'ALPHA_NUM',
		},
		{
			name: 'Store',
			property: 'store[code]',
			title: "The customer's default store",
			align: 'right',
			sortType: 'ALPHA_NUM',
		},
		{
			name: 'Company Name',
			property: 'companyName',
			title: '',
		},
		{
			name: 'Contact Name',
			property: 'contactName',
			title: '',
		},
		{
			name: 'Phone Number',
			property: 'phoneNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Fax Number',
			property: 'faxNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Mobile Number',
			property: 'mobilePhoneNumber',
			title: '',
			sortType: false,
		},
		{
			name: 'Balance',
			property: 'balance',
			title: '',
			align: 'right',
			sortType: 'ALPHA_NUM',
		},
		{
			name: 'Type',
			property: 'type',
			title: '',
		},
		{
			name: 'Date Entered',
			property: 'dateEntered',
			title: '',
			align: 'right',
		},
		{
			name: 'Street Address',
			property: 'billingAddress[address1]',
			title: '',
		},
		{
			name: 'City',
			property: 'billingAddress[city]',
			title: '',
		},
		{
			name: 'State',
			property: 'billingAddress[state]',
			title: '',
		},
		{
			name: 'Zip',
			property: 'billingAddress[zip]',
			title: '',
			align: 'right',
		},
		{
			name: 'Country',
			property: 'billingAddress[country]',
			title: '',
		},
		{
			name: 'Email Address',
			property: 'email',
			title: '',
		},
		{
			name: 'Web Address',
			property: 'webAddress',
			title: '',
		},
		{
			name: 'Default Term',
			property: 'defaultTerm[name]',
			title: "The customer's default invoice terms",
		},
		{
			name: 'Salesperson',
			property: 'salesPerson[name]',
			title: 'The primary salesperson on the account',
		},
		{
			name: 'Payment Method',
			property: 'defaultPaymentMethod[name]',
			title: "The customer's default payment method",
		},
		...getCustomerOptionColumns(customers[0]),
	]

	function getCustomerOptionColumns(customer: CustomerSearchResult) {
		if (!customer) {
			return []
		}
		const customerOptionColumns: Column<CustomerSearchResult>[] = []
		customer.optionValues.forEach((optionValue, index) => {
			if (optionValue.option.showInCustomerList) {
				customerOptionColumns.push({
					property: `optionValues[${index}][value]`,
					name: optionValue.option.label,
				})
			}
		})
		return customerOptionColumns
	}
</script>

<div class="card-body">
	<div class="d-flex justify-content-end align-items-center">
		<span class="mr-auto">{totalItemsCount ? `${totalItemsCount} Customers Found` : 'No customers found'}</span>
		<Select
			label="Results Per Page"
			labelClass="mr-2"
			labelParentClass="form-inline mb-0"
			showEmptyOption={false}
			bind:value={perPageCount}
			on:change={() => {
				asr.go(null, { perPageCount, pageNumber: 1 }, { inherit: true })
			}}
		>
			{#each perPageCounts as perPageCount}
				<option value={perPageCount}>{perPageCount}</option>
			{/each}
		</Select>
	</div>
	<Table
		responsive
		stickyHeader
		columnHidingEnabled
		{columns}
		{currentPageNumber}
		{totalItemsCount}
		rows={customers}
		perPageCount={parseInt(perPageCount, 10)}
		parentStyle="max-height: 60vh"
		on:pageChange={e => asr.go(null, { pageNumber: e.detail.pageNumber }, { inherit: true })}
		let:row={customer}
	>
		<svelte:fragment slot="no-rows">
			<tr>
				<td
					colspan={columns.length}
					class="text-center">No customers found</td
				>
			</tr>
		</svelte:fragment>
		<tr
			class="cursor-pointer"
			on:click={() => asr.go('app.customer', { customerId: customer.id })}
		>
			<Td property="id">{customer.id}</Td>
			<Td property="store[code]">{customer.store?.code ?? ''}</Td>
			<Td property="companyName">{customer.companyName}</Td>
			<Td property="contactName">{customer.contactName}</Td>
			<Td
				property="phoneNumber"
				class="text-nowrap"
			>
				{customer.phoneNumber}
			</Td>
			<Td
				property="faxNumber"
				class="text-nowrap"
			>
				{customer.faxNumber}
			</Td>
			<Td property="mobilePhoneNumber">{customer.mobilePhoneNumber}</Td>
			<Td property="balance">
				<span class={customerBalanceStatusMap[customer.balanceStatus].textColor}>{formatCustomerBalance(customer.balance)}</span>
			</Td>
			<Td property="type">{customer.type}</Td>
			<Td property="dateEntered">{customer.dateEntered ? customer.dateEntered.toLocaleDateString() : 'N/A'}</Td>
			<Td property="billingAddress[address1]">{customer.billingAddress.address1}</Td>
			<Td property="billingAddress[city]">{customer.billingAddress.city}</Td>
			<Td property="billingAddress[state]">{customer.billingAddress.state}</Td>
			<Td property="billingAddress[zip]">{customer.billingAddress.zip}</Td>
			<Td property="billingAddress[country]">{customer.billingAddress.country}</Td>
			<Td property="email">{customer.email}</Td>
			<Td property="webAddress">{customer.webAddress}</Td>
			<Td property="defaultTerm[name]">{customer.defaultTerm?.name ?? ''}</Td>
			<Td property="salesPerson[name]">{customer.salesPerson?.name ?? ''}</Td>
			<Td property="defaultPaymentMethod[name]">{customer.defaultPaymentMethod?.name ?? ''}</Td>
			{#each customer.optionValues as optionValue, index}
				{#if optionValue.option.showInCustomerList}
					<Td
						property={`optionValues[${index}][value]`}
						class={optionValue.option.dataType === 'CURRENCY' ? 'text-right' : ''}
					>
						{#if optionValue.option.dataType === 'CURRENCY'}
							{optionValue.value ? formatCustomerBalance(optionValue.value) : ''}
						{:else if optionValue.option.dataType === 'DATE'}
							{optionValue.value ? new Date(optionValue.value).toLocaleDateString() : ''}
						{:else}
							{optionValue.value}
						{/if}
					</Td>
				{/if}
			{/each}
		</tr>
	</Table>
</div>

<style>
	* :global(.text-dark-goldenrod) {
		color: darkgoldenrod;
	}
</style>
