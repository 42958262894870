<script lang="ts">
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import { klona } from 'klona'
	import { dequal } from 'dequal'
	import { getContext, tick } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { hasPermission } from 'utility/permission'
	import { getEventChecked } from '@isoftdata/browser-event'

	import { billDeliveryMethodOptions, type Store, type Customer, type SalesPerson, type State, type CustomerAddressData, type CustomerAlternateAddress } from 'utility/customer-helper'

	import AddressValidatorModal from './AddressValidatorModal.svelte'
	import AddressFields from './AddressFields.svelte'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'

	const alternateAddressCrudStore = getContext<CrudStore<CustomerAlternateAddress, 'uuid'>>('alternateAddressCrudStore')
	const newAddress: CustomerAddressData = Object.freeze({
		companyName: '',
		contactName: '',
		street: '',
		city: '',
		state: null,
		zip: '',
		country: '',
		email: '',
		faxNumber: '',
		phoneNumber: '',
		mobilePhoneNumber: '',
		department: '',
		notes: '',
		store: null,
		salesPerson: null,
		salesRegion: null,
		storeRegion: null,
		validated: false,
	})

	export let customer: Customer
	export let states: State[] = []
	export let stores: Store[] = []
	export let salesPersonList: SalesPerson[] = []
	export let customerChanged: boolean
	export let viewOnlyMode: boolean = false

	let addressLabelInput: HTMLInputElement | undefined
	let addressValidatorModalComponent: AddressValidatorModal
	let editAddressLabel: boolean = false
	let addNewAlternateAddress: boolean = false
	let showNotesField: boolean = false
	let matchBillingAddress: boolean = false
	let selectedAlternateAddressHasChanged: boolean = false
	let selectedAlternateAddressUuid: string
	let selectedAlternateAddress: CustomerAlternateAddress
	let alternateAddressDefaultStateMap: Map<string, CustomerAlternateAddress> = new Map()
	let customerAlternateAddressList: CustomerAlternateAddress[] = formatCustomerAlternateAddress(customer.addresses)
	let customerBillingAddress: CustomerAddressData = formatCustomerBillingAddress(customer)
	let validateAddressType: 'BILLING' | 'ALTERNATE' = 'ALTERNATE'

	let customerCanEditBillingAddress = hasPermission('CUSTOMERS_CAN_EDIT_BILLING_ADDRESS', customer.store?.id)
	let customerCanEditAlternateAddress = hasPermission('CUSTOMERS_CAN_EDIT_ADDRESSES', customer.store?.id)

	$: alternateAddressGroupByPrimary = customerAlternateAddressList.reduce(
		(acc: { primary: CustomerAlternateAddress[]; other: CustomerAlternateAddress[] }, address) => {
			if (address.primary) {
				acc.primary.push(address)
			} else {
				acc.other.push(address)
			}
			return acc
		},
		{ primary: [], other: [] },
	)
	$: showNotesField = !!selectedAlternateAddress.addressData.notes
	$: addressTitleRequired = selectedAlternateAddress.id === null ? !dequal(selectedAlternateAddress.addressData, newAddress) : true

	function formatCustomerBillingAddress(customer: Customer): CustomerAddressData {
		return {
			companyName: customer.companyName,
			contactName: customer.contactName,
			street: customer.billingAddress.address1,
			city: customer.billingAddress.city,
			state: customer.billingAddress.state,
			zip: customer.billingAddress.zip,
			country: customer.billingAddress.country,
			email: customer.email,
			faxNumber: customer.faxNumber,
			phoneNumber: customer.phoneNumber,
			mobilePhoneNumber: customer.mobilePhoneNumber,
			notes: customer.notes,
			store: customer.store,
			salesPerson: customer.salesPerson,
			salesRegion: customer.salesRegion,
			storeRegion: customer.storeRegion,
			validated: customer.billingAddressValidated,
		}
	}

	function formatCustomerAlternateAddress(address: Customer['addresses']): CustomerAlternateAddress[] {
		// if customer does not have alternate address, return a new alternate address and set addNewAlternateAddress to true
		let addressList: CustomerAlternateAddress[] = []
		if (address.length === 0) {
			addNewAlternateAddress = true
			addressList.push({
				id: null,
				uuid: uuid(),
				label: '',
				primary: false,
				addressData: klona(newAddress),
			})
			alternateAddressCrudStore.create(addressList[0])
		} else {
			addressList = address.map(address => {
				return {
					id: address.id,
					uuid: uuid(),
					label: address.label,
					primary: address.primary,
					addressData: {
						companyName: address.companyName,
						contactName: address.contactName,
						street: address.street,
						city: address.city,
						state: address.state,
						zip: address.zip,
						country: address.country,
						email: address.email,
						faxNumber: address.faxNumber,
						phoneNumber: address.phoneNumber,
						mobilePhoneNumber: address.mobilePhoneNumber,
						department: address.department,
						notes: address.notes,
						store: address.store,
						salesPerson: address.salesPerson,
						salesRegion: null, // alternate address does not have sales region
						storeRegion: null, // alternate address does not have store region
						validated: !!address.lastValidated,
					},
				}
			})
		}
		selectedAlternateAddress = addressList[0]
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		saveSelectedAlternateAddressDefaultState()
		return addressList
	}

	function addressIsReadyForValidation(addressData: CustomerAddressData): boolean {
		return !!addressData.street && !!addressData.city && !!addressData.state && !!addressData.zip && !!addressData.country
	}

	function updateCustomerBillingAddress(updatedAddressData: CustomerAddressData, validated: boolean) {
		customer.companyName = updatedAddressData.companyName
		customer.contactName = updatedAddressData.contactName
		customer.billingAddress = {
			...customer.billingAddress,
			address1: updatedAddressData.street,
			city: updatedAddressData.city,
			state: updatedAddressData.state,
			zip: updatedAddressData.zip,
			country: updatedAddressData.country,
		}
		customer.faxNumber = updatedAddressData.faxNumber
		customer.mobilePhoneNumber = updatedAddressData.mobilePhoneNumber
		customer.notes = updatedAddressData.notes
		customer.store = updatedAddressData.store
		customer.salesPerson = updatedAddressData.salesPerson
		customer.billingAddressValidated = validated
		customerChanged = true
	}

	function saveSelectedAlternateAddressDefaultState() {
		const existingDefaultState = alternateAddressDefaultStateMap.get(selectedAlternateAddress.uuid)
		if (existingDefaultState) {
			return
		}
		alternateAddressDefaultStateMap.set(selectedAlternateAddress.uuid, klona(selectedAlternateAddress))
	}

	function checkIfSelectedAlternateAddressHasChanged(alteranteAddress: CustomerAlternateAddress) {
		const foundAddressInCrudStore =
			alternateAddressCrudStore.createdValues.find(address => address.uuid === alteranteAddress.uuid) || alternateAddressCrudStore.updatedValues.find(address => address.uuid === alteranteAddress.uuid)
		selectedAlternateAddressHasChanged = !!foundAddressInCrudStore
	}

	function resetSelectedAlternateAddress() {
		// No id means that the address has not been saved yet, so we can just remove it from the list
		if (!selectedAlternateAddress.id) {
			if (confirm(`The address ${selectedAlternateAddress.label} has not been saved yet, reseting it will mean deleting it.\nDo you want to proceed?`)) {
				// remove it from the crud store
				// crudstore.delete does not remove the one in the create store, but it should be ok as we only send it to the API if the id is thruthy
				deleteAlternateAddress()
				return
			}
		}
		const defaultState = alternateAddressDefaultStateMap.get(selectedAlternateAddress.uuid)
		if (!defaultState) {
			return
		}
		selectedAlternateAddress = klona(defaultState)
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		const selectedAlternateAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
		customerAlternateAddressList[selectedAlternateAddressIndex] = selectedAlternateAddress
		selectedAlternateAddressHasChanged = false
	}

	async function newAlternateAddress() {
		addNewAlternateAddress = true
		selectedAlternateAddress = {
			id: null,
			uuid: uuid(),
			label: '',
			primary: false,
			addressData: klona(newAddress),
		}
		selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		customerAlternateAddressList.push(selectedAlternateAddress)
		customerAlternateAddressList = customerAlternateAddressList
		alternateAddressCrudStore.create(selectedAlternateAddress)
		await tick()
		addressLabelInput?.select()
	}

	function deleteAlternateAddress() {
		alternateAddressCrudStore.delete(selectedAlternateAddress)
		customerAlternateAddressList = customerAlternateAddressList.filter(address => address.uuid !== selectedAlternateAddress.uuid)
		if (customerAlternateAddressList.length === 0) {
			newAlternateAddress()
			selectedAlternateAddressHasChanged = false
		} else {
			selectedAlternateAddress = customerAlternateAddressList[0]
			selectedAlternateAddressUuid = selectedAlternateAddress.uuid
			checkIfSelectedAlternateAddressHasChanged(selectedAlternateAddress)
		}
	}

	async function makeAddressPrimary(event: Event) {
		const isPrimary = getEventChecked(event)
		// if the value is set to false, just set the primary address to false and return
		if (!isPrimary) {
			/*
			 * There is a visual bug on updating the option group on the select when we dont have a primary address after the change
			 * We suspect that it is due to that split second where the DOM could not find the selected uuid in the option when we "move" the selected address from the primary group to the other group
			 * To work around this, we set the selectedAlternateAddressUuid to an empty string first, then update the primary address, then set the selectedAlternateAddressUuid back, which is still the same
			 */
			selectedAlternateAddress.primary = false
			selectedAlternateAddressUuid = ''

			const selectedAlternateAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
			customerAlternateAddressList[selectedAlternateAddressIndex].primary = selectedAlternateAddress.primary
			alternateAddressCrudStore.update(selectedAlternateAddress)

			selectedAlternateAddressHasChanged = true
			customerChanged = true

			await tick()
			selectedAlternateAddressUuid = selectedAlternateAddress.uuid
		} else {
			// find the previous primary address
			const previousPrimaryAddress = customerAlternateAddressList.find(address => address.primary)

			// confirm if the user wants to change the primary address
			if (previousPrimaryAddress) {
				if (confirm(`The current primary address is ${previousPrimaryAddress.label}.\nDo you want to make ${selectedAlternateAddress.label} the primary address?`)) {
					previousPrimaryAddress.primary = false
					selectedAlternateAddress.primary = true

					const previousPrimaryAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === previousPrimaryAddress.uuid)
					const selectedAlternateAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
					customerAlternateAddressList[previousPrimaryAddressIndex] = previousPrimaryAddress
					customerAlternateAddressList[selectedAlternateAddressIndex] = selectedAlternateAddress

					alternateAddressCrudStore.update(previousPrimaryAddress)
					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					customerChanged = true
				} else {
					selectedAlternateAddress.primary = false
				}
			} else {
				if (confirm(`Do you want to make ${selectedAlternateAddress.label} the primary address?`)) {
					selectedAlternateAddress.primary = true

					const selectedAlternateAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
					customerAlternateAddressList[selectedAlternateAddressIndex] = selectedAlternateAddress

					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					customerChanged = true
				} else {
					selectedAlternateAddress.primary = false
				}
			}
		}
	}
</script>

<div class="card-deck">
	<div class="card">
		<div class="card-header d-flex justify-content-between align-items-center">
			<h5 class="mb-0">Billing Address</h5>
			{#if !customer.id}
				<small>* Company Name or Contact Name is required</small>
			{/if}
		</div>
		<div class="card-body">
			<div class="form-row">
				<div class="col-12 col-md-6">
					<Select
						required
						label="Bill Delivery Method"
						showEmptyOption={false}
						bind:value={customer.billDeliveryMethod}
						disabled={viewOnlyMode || !customerCanEditBillingAddress}
						on:change={() => {
							customerChanged = true
						}}
					>
						{#each billDeliveryMethodOptions as option}
							<option value={option.value}>{option.label}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-md-6">
					<Input
						label="Last Bill Date"
						value={customer.lastBillDate ? customer.lastBillDate.toLocaleString() : null}
						disabled
					/>
				</div>
				<hr class="w-100 mb-1" />
			</div>
			<AddressFields
				{states}
				{stores}
				{salesPersonList}
				disableAllFields={viewOnlyMode || !customerCanEditBillingAddress}
				bind:customerAddress={customerBillingAddress}
				on:addressChanged={() => {
					customerBillingAddress.validated = false
					updateCustomerBillingAddress(customerBillingAddress, false)
				}}
			/>
		</div>
		<div class="card-footer text-right">
			<Button
				outline
				size="sm"
				iconClass={customerBillingAddress.validated ? 'check' : 'magnifying-glass'}
				disabled={viewOnlyMode || customerBillingAddress.validated || matchBillingAddress || !addressIsReadyForValidation(customerBillingAddress)}
				on:click={() => {
					validateAddressType = 'BILLING'
					addressValidatorModalComponent.open(customerBillingAddress)
				}}
			>
				{customerBillingAddress.validated ? 'Validated' : 'Validate...'}
			</Button>
		</div>
	</div>
	<div class="card">
		<div class="card-header">
			<div class="d-flex justify-content-between align-items-center">
				<h5 class="mb-0">Alternate Address</h5>
				<Checkbox
					inline
					label="Match Billing"
					disabled={viewOnlyMode || !customerCanEditAlternateAddress}
					bind:checked={matchBillingAddress}
					on:change={() => {
						if (matchBillingAddress) {
							selectedAlternateAddress = {
								...selectedAlternateAddress,
								addressData: customerBillingAddress,
							}
							const selectedAlternateAddressIndex = customerAlternateAddressList.findIndex(address => address.uuid === selectedAlternateAddress.uuid)
							customerAlternateAddressList[selectedAlternateAddressIndex] = selectedAlternateAddress
							alternateAddressCrudStore.update(selectedAlternateAddress)
							customerChanged = true
						}
					}}
				/>
			</div>
		</div>
		<div class="card-body">
			<div class="form-row">
				<div class="col-12 col-md-6">
					{#if editAddressLabel || addNewAlternateAddress}
						<Input
							required={addressTitleRequired}
							id="addressLabelInput"
							label="Address Title"
							validation={{
								value: selectedAlternateAddress.label,
							}}
							bind:value={selectedAlternateAddress.label}
							bind:input={addressLabelInput}
							on:blur={() => {
								if (selectedAlternateAddress.label) {
									alternateAddressCrudStore.update(selectedAlternateAddress)
									addNewAlternateAddress = false
									editAddressLabel = false
									customerChanged = true
									selectedAlternateAddressHasChanged = true
								}
							}}
						/>
					{:else}
						<Select
							hintClickable
							hint="Edit Title"
							label="Alternate Address"
							showEmptyOption={false}
							hintClass={customerCanEditAlternateAddress ? '' : 'd-none'}
							bind:value={selectedAlternateAddressUuid}
							on:hintClick={async () => {
								editAddressLabel = true
								await tick()
								addressLabelInput?.select()
							}}
							on:change={() => {
								const selectedAddress = customerAlternateAddressList.find(address => address.uuid === selectedAlternateAddressUuid)
								if (selectedAddress) {
									selectedAlternateAddress = selectedAddress
									checkIfSelectedAlternateAddressHasChanged(selectedAlternateAddress)
									// This means that the size of the Map could be as big as the number of alternate addresses, not sure if this is a good idea
									saveSelectedAlternateAddressDefaultState()
								}
							}}
						>
							{#each Object.entries(alternateAddressGroupByPrimary) as [key, value]}
								<optgroup label={key === 'primary' ? 'Primary Address' : 'Other Addresses'}>
									{#each value as address (address.uuid)}
										<option value={address.uuid}>{address.label}</option>
									{:else}
										<option disabled>No Address</option>
									{/each}
								</optgroup>
							{/each}
						</Select>
					{/if}
				</div>
				<div class="col-auto d-flex align-items-end">
					<Checkbox
						label="Primary Address"
						checked={selectedAlternateAddress.primary}
						disabled={viewOnlyMode || !selectedAlternateAddress.label || !customerCanEditAlternateAddress}
						on:change={makeAddressPrimary}
					/>
				</div>
			</div>
			<hr class="w-100 mb-1" />
			<AddressFields
				{states}
				{stores}
				{salesPersonList}
				{showNotesField}
				addressType="ALTERNATE"
				disableAllFields={matchBillingAddress || viewOnlyMode || !customerCanEditAlternateAddress}
				bind:customerAddress={selectedAlternateAddress.addressData}
				on:addressChanged={() => {
					alternateAddressCrudStore.update(selectedAlternateAddress)
					selectedAlternateAddressHasChanged = true
					customerChanged = true
				}}
			/>
			{#if !showNotesField && !viewOnlyMode && customerCanEditAlternateAddress}
				<Button
					size="sm"
					color="link"
					class="p-0"
					on:click={() => {
						showNotesField = true
					}}
				>
					Add Notes
				</Button>
			{/if}
		</div>
		<div class="card-footer d-flex justify-content-between">
			<div>
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={viewOnlyMode || !customerCanEditAlternateAddress}
					on:click={async () => {
						newAlternateAddress()
					}}
				>
					New Address
				</Button>
				<Button
					outline
					size="sm"
					color="danger"
					iconClass="trash"
					disabled={viewOnlyMode || !customerCanEditAlternateAddress}
					on:click={() => {
						if (confirm('Are you sure you want to delete this address?')) {
							deleteAlternateAddress()
							customerChanged = true
						}
					}}
				>
					Delete...
				</Button>
				{#if selectedAlternateAddressHasChanged && !viewOnlyMode}
					<Button
						outline
						color="secondary"
						size="sm"
						iconClass="broom"
						on:click={() => {
							resetSelectedAlternateAddress()
						}}
					>
						Reset...
					</Button>
				{/if}
			</div>
			<div>
				<Button
					outline
					color="primary"
					size="sm"
					iconClass={selectedAlternateAddress.addressData.validated ? 'check' : 'magnifying-glass'}
					disabled={viewOnlyMode ||
						!!selectedAlternateAddress.addressData.validated ||
						(selectedAlternateAddress.addressData.validated && !selectedAlternateAddressHasChanged) ||
						matchBillingAddress ||
						!customerCanEditAlternateAddress ||
						!addressIsReadyForValidation(selectedAlternateAddress.addressData)}
					on:click={() => {
						validateAddressType = 'ALTERNATE'
						addressValidatorModalComponent.open(selectedAlternateAddress.addressData)
					}}
				>
					{selectedAlternateAddress.addressData.validated ? 'Validated' : 'Validate...'}
				</Button>
			</div>
		</div>
	</div>
</div>

<AddressValidatorModal
	{states}
	bind:this={addressValidatorModalComponent}
	on:selectAddressData={({ detail }) => {
		if (validateAddressType === 'BILLING') {
			customerBillingAddress = detail.addressData
			updateCustomerBillingAddress(customerBillingAddress, true)
		} else {
			selectedAlternateAddress.addressData = detail.addressData
			alternateAddressCrudStore.update(selectedAlternateAddress)
			selectedAlternateAddressHasChanged = true
		}

		customerChanged = true
	}}
></AddressValidatorModal>
