<script lang="ts">
	import type { Mediator } from 'types/common'
	import type { ReportJob } from '@isoftdata/svelte-report-job-modal'
	import type { FavoriteReportsStore } from 'stores/favorite-reports'

	import ReportJobModal from '@isoftdata/svelte-report-job-modal'
	import { getContext, onMount, type ComponentProps } from 'svelte'
	import { graphql, GenerateCrystalReportStore } from '$houdini'
	import session from 'stores/session'
	import makeFavoriteReportsStore from 'stores/favorite-reports'
	import loadReports from 'utility/report-viewer-helper'

	export let showPreview: boolean = true
	export let storeId: number = $session.currentStore

	const mediator = getContext<Mediator>('mediator')

	type OpenFn = Exclude<ComponentProps<ReportJobModal>['open'], undefined>
	type Params = Parameters<OpenFn>

	let internalModal: ReportJobModal
	let showPrint = false
	let favoriteReports: FavoriteReportsStore | undefined = undefined
	let favoriteReportNames: Array<string> = []
	// dumb hack because I have to init the favorite store before I can set the value
	$: favoriteReports?.set(favoriteReportNames)

	export async function open(reportJob: Params[0], options?: Params[1]) {
		const contextEmails = options?.emails?.filter(e => e) ?? []

		await internalModal.open(reportJob, {
			...options,
			emails: contextEmails.length ? contextEmails : await loadSuggestedEmails(),
		})
	}

	async function loadSuggestedEmails() {
		const { data } = await getEmailSuggestions.fetch()
		return data?.getEmailSuggestions ?? []
	}

	async function loadPrinters(reportType: string) {
		const { data } = await getPrinters.fetch({
			variables: {
				filter: {
					reportTypes: [reportType],
					storeIds: [storeId],
				},
			},
		})

		showPrint = !!data?.printers?.items?.length

		return data?.printers?.items ?? []
	}

	async function loadDefaultPrinter(reportType: string): Promise<string> {
		const { data } = await getDefaultReportPrinter.fetch({
			variables: {
				input: {
					reportType,
				},
			},
		})
		return data?.getDefaultReportPrinter?.printer ?? ''
	}

	async function generatePdfPreview({ name, type, parameters, id }: Parameters<ComponentProps<ReportJobModal>['generatePdfPreview']>[0]): Promise<{ data: string; mimeType: string }> {
		const { data } = await generateCrystalReport.mutate({
			input: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id,
			},
		})
		if (!data) {
			throw new Error('No data returned from generateCrystalReport')
		}
		return data.generateCrystalReport
	}

	async function saveReportJobs(reportJobs: ReportJob[]): Promise<void> {
		if (!reportJobs.every(job => job.reportId)) {
			return mediator?.publish('showMessage', {
				heading: 'Error saving report jobs',
				message: 'Report ID is required',
				type: 'danger',
				time: false,
			})
		}
		try {
			await Promise.all(
				reportJobs.map(({ parameters, ...reportJob }) => {
					createReportQueueJob.mutate({
						input: {
							...reportJob,
							reportId: reportJob.reportId!,
							parameters: Object.entries(parameters).map(([key, value]) => {
								return {
									parameterName: key,
									value,
								}
							}),
						},
					})
				}),
			)
		} catch (err: any) {
			console.error(err)
			mediator?.publish('showMessage', {
				heading: 'Error saving report jobs',
				message: err.message,
				type: 'danger',
				time: false,
			})
		}
	}

	async function savePrinterPreference(printer: string, reportType: string): Promise<void> {
		try {
			await setDefaultReportPrinterMutation.mutate({
				input: {
					printer,
					reportType,
					storeId,
					userId: $session.id,
				},
			})
		} catch (err) {
			console.error(err)
		}
	}

	const getDefaultReportPrinter = graphql(`
		query GetDefaultReportPrinter($input: GetReportPrinter!) {
			getDefaultReportPrinter(input: $input) {
				printer
			}
		}
	`)

	const setDefaultReportPrinterMutation = graphql(`
		mutation SetDefaultReportPrinterMutation($input: SetDefaultReportPrinter!) {
			setDefaultReportPrinter(input: $input) {
				id
			}
		}
	`)

	const createReportQueueJob = graphql(`
		mutation CreateReportQueueJob($input: NewReportQueue!) {
			createReportQueueJob(input: $input) {
				id
			}
		}
	`)

	const getPrinters = graphql(`
		query GetPrinters($filter: ReportQueuePrinterFilter) {
			printers: reportQueuePrinters(filter: $filter, pagination: { pageSize: 10000, pageNumber: 1 }) {
				items {
					dateLastSeen
					displayName
					hidden
					id
					name
					online
					storeId
				}
			}
		}
	`)

	const generateCrystalReport = new GenerateCrystalReportStore()

	const getEmailSuggestions = graphql(`
		query GetEmailSuggestions {
			getEmailSuggestions
		}
	`)

	const getFavoriteReports = graphql(`
		query GetFavoriteReports {
			session {
				userAccount {
					favoriteReports: settings {
						reportViewer {
							favoriteReportNames
						}
					}
				}
			}
		}
	`)

	onMount(async () => {
		const { data } = await getFavoriteReports.fetch()
		favoriteReports = makeFavoriteReportsStore(data?.session?.userAccount?.favoriteReports?.reportViewer?.favoriteReportNames ?? '')
		favoriteReportNames = $favoriteReports ?? []
	})
</script>

<!-- @component To use this component, you can simply call its `open` function

```svelte
<script lang="ts">
	import ReportJobModal from 'components/ReportJobModal.svelte'

	let reportJobModal: ReportJobModal | undefined = undefined
	reportJobModal.open(
		{
		// print job details
		type: 'Work Order',
		parameters: { workorderid: workOrder.id.toString() },
		},
		{
		// other options
		},
	)
</script>
<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
```
-->

<ReportJobModal
	{showPrint}
	{showPreview}
	loadReports={reportType =>
		loadReports({
			filter: {
				type: reportType ? [reportType] : undefined,
			},
		})}
	{loadPrinters}
	{loadDefaultPrinter}
	{generatePdfPreview}
	{saveReportJobs}
	{savePrinterPreference}
	bind:favoriteReportNames
	bind:this={internalModal}
></ReportJobModal>
