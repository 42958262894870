<script lang="ts">
	import type { Customer, PaymentMethod, SalesOrderTerm, State, TaxItem } from 'utility/customer-helper'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import { getEventChecked, getEventValue } from '@isoftdata/browser-event'
	import { customerBalanceStatusMap, formatCustomerBalance } from 'utility/customer-helper'
	import { priceTypeOptions } from 'utility/customer-helper'
	import { hasPermission } from 'utility/permission'
	import { getContext, tick } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'

	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import CurrencyInput from '@isoftdata/svelte-currency-input'
	import Input from '@isoftdata/svelte-input'
	import Label from '@isoftdata/svelte-label'
	import Select from '@isoftdata/svelte-select'
	import Table, { type Column, Td } from '@isoftdata/svelte-table'

	export let customer: Customer
	export let states: State[] = []
	export let paymentMethods: PaymentMethod[] = []
	export let salesOrderTerms: SalesOrderTerm[] = []
	export let taxItems: TaxItem[] = []
	export let customerChanged: boolean
	export let viewOnlyMode: boolean = false

	const paymentMethodCrudStore = getContext<CrudStore<Customer['allowedPaymentMethods'][number], 'id'>>('paymentMethodCrudStore')
	const taxExemptionCrudStore = getContext<CrudStore<Customer['taxExemptions'][number], 'uuid'>>('taxExemptionCrudStore')
	const columns: Column[] = [
		{ property: 'state', name: 'State' },
		{ property: 'number', name: 'Tax ID', align: 'right' },
		{ property: 'expirationDate', name: 'Expiration', align: 'right' },
		{ property: 'notes', name: 'Notes' },
		{ property: 'deleteAction', name: '', align: 'center', width: '1rem' },
	]

	let useCustomLaborRate: boolean = customer.laborRateType !== 'DEFAULT'
	let showExpired: boolean = false
	let selectedStateFilter: string | null = null
	let initialCustomerAllowedPaymentMethodIds: number[] = customer.allowedPaymentMethods.map(paymentMethod => paymentMethod.id)

	$: customerAllowedPaymentMethodIds = customer.allowedPaymentMethods.map(paymentMethod => paymentMethod.id)
	$: customerTaxExemptions = selectedStateFilter ? customer.taxExemptions.filter(taxExemption => taxExemption.state === selectedStateFilter) : customer.taxExemptions

	function selectAndUnselectAllPaymentMethods() {
		paymentMethodCrudStore.clear()
		if (customer.allowedPaymentMethods.length === paymentMethods.length) {
			customer.allowedPaymentMethods.forEach(allowedPaymentMethod => {
				if (initialCustomerAllowedPaymentMethodIds.includes(allowedPaymentMethod.id)) {
					paymentMethodCrudStore.delete(allowedPaymentMethod)
				}
			})
			customer.allowedPaymentMethods = []
			customer.defaultPaymentMethod = null
		} else {
			customer.allowedPaymentMethods = paymentMethods
			customer.allowedPaymentMethods.forEach(allowedPaymentMethod => {
				if (!initialCustomerAllowedPaymentMethodIds.includes(allowedPaymentMethod.id)) {
					paymentMethodCrudStore.create(allowedPaymentMethod)
				}
			})
		}
		customerChanged = true
	}

	function toggleSinglePaymentMethod(paymentMethodId: number) {
		const selectedPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.id === paymentMethodId)
		if (!selectedPaymentMethod) {
			return
		} else if (customerAllowedPaymentMethodIds.includes(paymentMethodId)) {
			// Remove the payment method from the customer's allowed payment methods
			customer.allowedPaymentMethods = customer.allowedPaymentMethods.filter(allowedPaymentMethod => allowedPaymentMethod.id !== paymentMethodId)
			if (initialCustomerAllowedPaymentMethodIds.includes(paymentMethodId)) {
				paymentMethodCrudStore.delete(selectedPaymentMethod)
			}

			const createdPaymentMethods = Object.values($paymentMethodCrudStore.created)
			if (createdPaymentMethods.includes(selectedPaymentMethod)) {
				paymentMethodCrudStore.clear()
				createdPaymentMethods.forEach(createdPaymentMethod => {
					if (createdPaymentMethod.id !== paymentMethodId) {
						paymentMethodCrudStore.create(createdPaymentMethod)
					}
				})
			}

			if (customer.defaultPaymentMethod?.id === paymentMethodId) {
				customer.defaultPaymentMethod = null
			}
		} else {
			// Add the payment method to the customer's allowed payment methods
			customer.allowedPaymentMethods = [...customer.allowedPaymentMethods, selectedPaymentMethod]
			if (!initialCustomerAllowedPaymentMethodIds.includes(paymentMethodId)) {
				paymentMethodCrudStore.create(selectedPaymentMethod)
			} else {
				const deletedPaymentMethod = Object.values($paymentMethodCrudStore.deleted).find(deletedPaymentMethod => deletedPaymentMethod.id === paymentMethodId)
				if (deletedPaymentMethod) {
					paymentMethodCrudStore.unDelete(deletedPaymentMethod)
				}
			}
		}
		customerChanged = true
	}

	async function addTaxExemption() {
		const newTaxExemption = {
			id: null,
			uuid: uuid(),
			state: '',
			number: '',
			expirationDate: null,
			notes: '',
		}
		customer.taxExemptions.push(newTaxExemption)
		customer.taxExemptions = customer.taxExemptions
		taxExemptionCrudStore.create(newTaxExemption)
		await tick()
		document.getElementById(`tax-exemption-${customer.taxExemptions.length - 1}`)?.focus()
	}

	function updateTaxExemption(event: Event, index: number, key: string) {
		const value = getEventValue(event)
		customer.taxExemptions[index][key] = customer.taxExemptions[index][key] === 'expirationDate' ? new Date(value) : value
		taxExemptionCrudStore.update(customer.taxExemptions[index])
		customerChanged = true
	}
</script>

<div class="form-row">
	<div class="col-12">
		<div class="card">
			<div class="card-header">
				<h5 class="mb-0">Balance Information</h5>
			</div>
			<div class="card-body">
				<div class="form-row">
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="Last 30 Days"
							class="text-right"
							value={formatCustomerBalance(customer.aging.zeroToThirtyDays)}
						/>
					</div>
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="31 to 60 Days"
							class="text-right"
							value={formatCustomerBalance(customer.aging.thirtyOneToSixtyDays)}
						/>
					</div>
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="61 to 90 Days"
							class="text-right"
							value={formatCustomerBalance(customer.aging.sixtyOneToNinetyDays)}
						/>
					</div>
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="91 to 120 Days"
							class="text-right"
							value={formatCustomerBalance(customer.aging.ninetyOneToOneHundredTwentyDays)}
						/>
					</div>
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="Over 120 Days"
							class="text-right"
							value={formatCustomerBalance(customer.aging.overOneHundredTwentyDays)}
						/>
					</div>
					<div class="col-4 col-md-2">
						<Input
							readonly
							label="Total Due"
							class="text-right {customerBalanceStatusMap[customer.balanceStatus].textColor}"
							value={formatCustomerBalance(customer.balance)}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6 mt-2">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Document Settings & Defaults</h5>
			</div>
			<div class="card-body">
				<div class="form-row">
					<div class="col-12 col-md-6">
						<!-- TODO: add some description for these checkboxes, should be able to get it from the desktop code -->
						<Checkbox
							label="Require PO# On Sales Orders"
							disabled={viewOnlyMode}
							bind:checked={customer.requirePurchaseOrderNumber}
							on:change={() => (customerChanged = true)}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Checkbox
							label="Accepts Partial Shipments"
							disabled={viewOnlyMode}
							bind:checked={customer.acceptsPartialShipments}
							on:change={() => (customerChanged = true)}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Checkbox
							label="Require Full Payment On Invoice Finalization"
							disabled={viewOnlyMode}
							bind:checked={customer.cashOnly}
							on:change={() => (customerChanged = true)}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Checkbox
							label="Accepts Back Orders"
							disabled={viewOnlyMode}
							bind:checked={customer.acceptsBackOrders}
							on:change={() => (customerChanged = true)}
						/>
					</div>

					<hr class="w-100 mb-1" />
					<div class="col-12">
						<CurrencyInput
							label="Account Limit"
							labelParentClass="w-50"
							disabled={viewOnlyMode || !hasPermission('CUSTOMERS_CAN_EDIT_CREDIT_LIMIT', customer.store?.id)}
							bind:value={customer.accountLimit}
							on:change={() => (customerChanged = true)}
						/>
					</div>
					<hr class="w-100 mb-1" />
					<div class="col-12 col-md-6">
						<div class="form-group">
							<Label
								required
								parentClass="mb-0"
								label="Default Pricing Level"
							/>
							<div class="d-flex align-items-center">
								<div class="input-group input-group-sm w-auto">
									<input
										required
										type="number"
										inputmode="decimal"
										class="form-control"
										disabled={viewOnlyMode}
										bind:value={customer.defaultPricePercentage}
										on:change={() => {
											if (!customer.defaultPricePercentage) {
												customer.defaultPricePercentage = '0'
											}
											customerChanged = true
										}}
									/>
									<div class="input-group-append">
										<span class="input-group-text">%</span>
									</div>
								</div>
								<span class="ml-2 mr-2">of</span>
								<Select
									required
									showLabel={false}
									showEmptyOption={false}
									labelParentClass="mb-0 w-100"
									disabled={viewOnlyMode}
									bind:value={customer.defaultPriceType}
									on:change={() => (customerChanged = true)}
								>
									{#each priceTypeOptions as priceTypeOption}
										<option value={priceTypeOption.value}>{priceTypeOption.label}</option>
									{/each}
								</Select>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="col-form-label">
							<Checkbox
								inline
								label="Use Custom WO Labor Rate"
								disabled={viewOnlyMode}
								bind:checked={useCustomLaborRate}
								on:change={() => {
									if (!useCustomLaborRate) {
										customer.customLaborRate = '0'
										customer.laborRateType = 'DEFAULT'
									}
									customerChanged = true
								}}
							/>
						</div>
						{#if customer.laborRateType === 'PERCENT'}
							<div class="input-group input-group-sm w-auto">
								<input
									type="number"
									inputmode="decimal"
									class="form-control"
									placeholder="0"
									disabled={!useCustomLaborRate || viewOnlyMode}
									bind:value={customer.customLaborRate}
									on:change={() => (customerChanged = true)}
								/>
								<div class="input-group-append">
									<span class="input-group-text">%</span>
								</div>
							</div>
						{:else}
							<CurrencyInput
								showLabel={false}
								labelParentClass="mb-0"
								required={useCustomLaborRate}
								disabled={!useCustomLaborRate || viewOnlyMode}
								bind:value={customer.customLaborRate}
								on:change={() => (customerChanged = true)}
							/>
						{/if}
						<Checkbox
							label="Use Percent"
							checked={customer.laborRateType === 'PERCENT'}
							disabled={!useCustomLaborRate || viewOnlyMode}
							on:change={event => {
								const value = getEventChecked(event)
								customer.laborRateType = value ? 'PERCENT' : 'FIXED'
							}}
						/>
					</div>

					<div class="col-12 col-md-6">
						<Select
							label="Default Invoice Terms"
							emptyValue={null}
							options={salesOrderTerms.map(term => term.name)}
							value={customer.defaultTerm?.name ?? null}
							disabled={viewOnlyMode || !hasPermission('CUSTOMERS_CAN_EDIT_TERMS', customer.store?.id)}
							on:change={event => {
								const value = getEventValue(event)
								const selectedDefaultTerm = salesOrderTerms.find(term => term.name === value)
								if (selectedDefaultTerm) {
									customer.defaultTerm = selectedDefaultTerm
								}
								customerChanged = true
							}}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Select
							label="Default Invoice Tax"
							emptyValue={null}
							options={taxItems.map(taxItem => taxItem.name)}
							value={customer.defaultTaxItem?.name ?? null}
							disabled={viewOnlyMode}
							on:change={event => {
								const value = getEventValue(event)
								const selectedDefaultTaxItem = taxItems.find(taxItem => taxItem.name === value)
								if (selectedDefaultTaxItem) {
									customer.defaultTaxItem = selectedDefaultTaxItem
								}
								customerChanged = true
							}}
						/>
					</div>
					<!-- Will come back to this once we got a clarification on this feature -->
					<!-- <div class="col-12 col-md-6">
						<Input
							label="Default PO#"
							value={customer.blanketPurchaseOrderNumber}
						/>
					</div>
					<div class="col-12 col-md-6">
						<Input
							type="date"
							label="PO# Expiration Date"
							value={customer.blanketPurchaseOrderNumberExpiration ? customer.blanketPurchaseOrderNumberExpiration.toISOString().split('T')[0] : null}
						/>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6 mt-2">
		<div class="card h-100">
			<div class="card-header">
				<h5 class="mb-0">Payment Methods</h5>
			</div>
			<div class="card-body">
				<Select
					labelParentClass="mb-2"
					label="Default"
					emptyValue={null}
					emptyText="No Default Payment Method"
					options={customer.allowedPaymentMethods.map(paymentMethod => paymentMethod.name)}
					value={customer.defaultPaymentMethod?.name ?? null}
					disabled={viewOnlyMode}
					on:change={event => {
						const value = getEventValue(event)
						const selectedDefaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.name === value)
						if (selectedDefaultPaymentMethod) {
							customer.defaultPaymentMethod = selectedDefaultPaymentMethod
						} else {
							customer.defaultPaymentMethod = null
						}
						customerChanged = true
					}}
				/>
				<Label
					parentClass="mb-0"
					label="Allowed"
				>
					<div
						class="list-group"
						style="max-height: 35vh; overflow-y: auto;"
					>
						{#each paymentMethods as paymentMethod}
							<button
								class="list-group-item list-group-item-action"
								class:list-group-item-primary={customerAllowedPaymentMethodIds.includes(paymentMethod.id)}
								disabled={viewOnlyMode}
								on:click={() => toggleSinglePaymentMethod(paymentMethod.id)}
							>
								<div class="d-flex justify-content-between align-items-center">
									<div>
										<i class={`fas fa-fw ${customerAllowedPaymentMethodIds.includes(paymentMethod.id) ? 'fa-check' : ''}`} />
										{paymentMethod.name}
									</div>
									{#if customer.defaultPaymentMethod?.name === paymentMethod.name}
										<span class="badge badge-pill badge-success">Default</span>
									{/if}
								</div>
							</button>
						{/each}
					</div>
				</Label>
			</div>
			<div class="card-footer">
				<Button
					outline
					size="sm"
					color="primary"
					iconClass="check-double"
					disabled={viewOnlyMode}
					on:click={selectAndUnselectAllPaymentMethods}
				>
					{customer.allowedPaymentMethods.length === paymentMethods.length ? 'Disallow All' : 'Allow All'}
				</Button>
			</div>
		</div>
	</div>
	<div class="col-12 mt-2">
		<div class="card">
			<div class="card-header">
				<h5 class="mb-0">Tax Exemptions</h5>
			</div>
			<div class="card-body">
				<div class="form-row align-items-end mb-1">
					<div class="col-2">
						<Select
							label="State Filter"
							emptyText="All States"
							options={states.map(state => state.stateAbbreviation)}
							bind:value={selectedStateFilter}
						/>
					</div>
					<div class="col-2">
						<Checkbox
							inline
							label="Show Expired"
							bind:checked={showExpired}
						/>
					</div>
				</div>
				<Table
					responsive
					{columns}
					parentStyle="overflow-y: auto; max-height: 45vh;"
					rows={customerTaxExemptions}
					let:row
				>
					<svelte:fragment slot="no-rows">
						<tr>
							<td
								colspan="4"
								class="text-center"
							>
								No Tax Exemptions
							</td>
						</tr>
					</svelte:fragment>
					{#if showExpired || row.number.toLowerCase() !== 'expired'}
						<tr>
							<Td property="state">
								<Select
									showLabel={false}
									emptyText="N/A"
									emptyValue=""
									options={states.map(state => state.stateAbbreviation)}
									value={row.state}
									disabled={viewOnlyMode}
									on:change={event => {
										updateTaxExemption(event, row.originalIndex, 'state')
									}}
								/>
							</Td>
							<Td property="number">
								<Input
									required
									id="tax-exemption-{row.originalIndex}"
									showLabel={false}
									value={row.number}
									disabled={viewOnlyMode}
									on:change={event => {
										updateTaxExemption(event, row.originalIndex, 'number')
									}}
								/>
							</Td>
							<Td property="expirationDate">
								<Input
									type="date"
									showLabel={false}
									value={row.expirationDate ? new Date(row.expirationDate).toISOString().split('T')[0] : null}
									disabled={viewOnlyMode}
									on:change={event => {
										updateTaxExemption(event, row.originalIndex, 'expirationDate')
									}}
								/>
							</Td>
							<Td property="notes">
								<Input
									showLabel={false}
									value={row.notes}
									disabled={viewOnlyMode}
									on:change={event => {
										updateTaxExemption(event, row.originalIndex, 'notes')
									}}
								/>
							</Td>
							<Td property="deleteAction">
								<Button
									outline
									size="sm"
									color="danger"
									iconClass="trash"
									disabled={viewOnlyMode}
									on:click={() => {
										customer.taxExemptions = customer.taxExemptions.filter(taxExemption => taxExemption.uuid !== row.uuid)
										taxExemptionCrudStore.delete(row)
										customerChanged = true
									}}
								/>
							</Td>
						</tr>
					{/if}
				</Table>
			</div>
			<div class="card-footer">
				<Button
					outline
					size="sm"
					color="success"
					iconClass="plus"
					disabled={viewOnlyMode}
					on:click={() => addTaxExemption()}
				>
					New Tax Exemption
				</Button>
			</div>
		</div>
	</div>
</div>
