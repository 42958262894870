export default {
    "name": "AutofillByZip",
    "kind": "HoudiniQuery",
    "hash": "5badb41b3a11ff6cd2d2e04e4c2f7b0e79c9927f98dd2dc92d8278b20f81253b",

    "raw": `query AutofillByZip($zipcode: String) {
  autofillByZip(zipcode: $zipcode) {
    city
    country
    stateAbbreviation
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "autofillByZip": {
                "type": "ZipcodeOutput",
                "keyRaw": "autofillByZip(zipcode: $zipcode)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "nullable": true,
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "nullable": true,
                            "visible": true
                        },

                        "stateAbbreviation": {
                            "type": "String",
                            "keyRaw": "stateAbbreviation",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "zipcode": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=2a2fb14d10207d30e4744dfa71d841c0a32aabffb070d4094b613f30513ba7e0";