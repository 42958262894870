export default {
    "name": "CreateCustomer",
    "kind": "HoudiniMutation",
    "hash": "4149ff404b560e588d22464995b310a938563e5b96e1bed42d1675a24436fff6",

    "raw": `mutation CreateCustomer($input: NewCustomer!) {
  createCustomer(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createCustomer": {
                "type": "Customer",
                "keyRaw": "createCustomer(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "NewCustomer"
        },

        "types": {
            "NewCustomerAddress": {
                "city": "String",
                "companyName": "String",
                "contactName": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "faxNumber": "String",
                "label": "String",
                "mobilePhoneNumber": "String",
                "notes": "String",
                "phoneNumber": "String",
                "primary": "Boolean",
                "salesPersonId": "Int",
                "state": "String",
                "storeId": "Int",
                "street": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NewCustomerAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "AddressInput": {
                "address": "String",
                "city": "String",
                "country": "String",
                "state": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NestedCustomerOptionValueUpdate": {
                "optionId": "Int",
                "value": "String"
            },

            "NewTaxExemption": {
                "expiration": "Date",
                "notes": "String",
                "number": "String",
                "state": "String"
            },

            "NewCustomer": {
                "acceptsBackOrders": "Boolean",
                "acceptsPartialShipments": "Boolean",
                "accountLimit": "Decimal2",
                "active": "Boolean",
                "addresses": "NewCustomerAddress",
                "allowedPaymentMethods": "Int",
                "attachments": "NewCustomerAttachment",
                "billDeliveryMethod": "BillDeliveryMethod",
                "billingAddress": "AddressInput",
                "blanketPurchaseOrderNumber": "String",
                "blanketPurchaseOrderNumberExpiration": "Date",
                "cashOnly": "Boolean",
                "companyName": "String",
                "contactName": "String",
                "customLaborRate": "Decimal2",
                "defaultPaymentMethodId": "UInt",
                "defaultPricePercentage": "Decimal2",
                "defaultPriceType": "PriceType",
                "defaultTaxItemId": "UInt",
                "defaultTermId": "UInt",
                "driverLicenseNumber": "String",
                "dunsNumber": "Int",
                "email": "String",
                "faxNumber": "String",
                "laborRateType": "LaborRateType",
                "mobilePhoneNumber": "String",
                "notes": "String",
                "optionValues": "NestedCustomerOptionValueUpdate",
                "phoneNumber": "String",
                "requirePurchaseOrderNumber": "Boolean",
                "salespersonId": "UInt",
                "storeId": "UInt",
                "taxExemptions": "NewTaxExemption",
                "type": "String",
                "webAddress": "String",
                "webUserId": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a20bc8dedb34bdf5bd64fd0c139c1c69fd26599675b4b99ef24c8a6efc1f64b2";