export default {
    "name": "CustomerData",
    "kind": "HoudiniQuery",
    "hash": "0aa2da7dca2282023ba517fb0a7c57eba75757fbf805b4e2f7b42c31da4ae93f",

    "raw": `query CustomerData($customerId: UInt!) {
  customer(id: $customerId) {
    addresses {
      id
      city
      companyName
      contactName
      country
      salesPerson {
        id
        name
      }
      store {
        id
        name
      }
      department
      email
      faxNumber
      primary
      label
      lastValidated
      mobilePhoneNumber
      notes
      phoneNumber
      state
      street
      zip
    }
    contactName
    companyName
    balance
    billingAddress {
      lastValidated
      address1
      address2
      city
      country
      county
      email
      phone
      state
      zip
    }
    email
    faxNumber
    id
    mobilePhoneNumber
    phoneNumber
    salesPerson {
      id
      name
    }
    store {
      id
      name
    }
    lastBillDate
    billDeliveryMethod
    active
    type
    driverLicenseNumber
    webAddress
    dateEntered
    notes
    optionValues {
      option {
        id
        label
        showInCustomerList
        dataType
      }
      value
    }
    attachments {
      id
      public
      rank
      file {
        id
        created
        extension
        mimetype
        name
        hash
        type
        updated
        path
        size
        host
        url
      }
    }
    acceptsBackOrders
    acceptsPartialShipments
    accountLimit
    aging {
      zeroToThirtyDays
      thirtyOneToSixtyDays
      sixtyOneToNinetyDays
      ninetyOneToOneHundredTwentyDays
      overOneHundredTwentyDays
    }
    allowedPaymentMethods {
      id
      name
    }
    cashOnly
    customLaborRate
    laborRateType
    defaultPaymentMethod {
      id
      name
    }
    defaultPricePercentage
    defaultPriceType
    defaultTaxItem {
      id
      name
    }
    defaultTerm {
      id
      name
      code
      customerMemo
    }
    requirePurchaseOrderNumber
    taxExemptions {
      id
      expirationDate
      notes
      number
      state
    }
    blanketPurchaseOrderNumber
    blanketPurchaseOrderNumberExpiration
    salesRegion
    storeRegion
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "customer": {
                "type": "Customer",
                "keyRaw": "customer(id: $customerId)",

                "selection": {
                    "fields": {
                        "addresses": {
                            "type": "CustomerAddress",
                            "keyRaw": "addresses",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "companyName": {
                                        "type": "String",
                                        "keyRaw": "companyName",
                                        "visible": true
                                    },

                                    "contactName": {
                                        "type": "String",
                                        "keyRaw": "contactName",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "salesPerson": {
                                        "type": "UserAccount",
                                        "keyRaw": "salesPerson",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "store": {
                                        "type": "Store",
                                        "keyRaw": "store",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "department": {
                                        "type": "String",
                                        "keyRaw": "department",
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "visible": true
                                    },

                                    "faxNumber": {
                                        "type": "String",
                                        "keyRaw": "faxNumber",
                                        "visible": true
                                    },

                                    "primary": {
                                        "type": "Boolean",
                                        "keyRaw": "primary",
                                        "visible": true
                                    },

                                    "label": {
                                        "type": "String",
                                        "keyRaw": "label",
                                        "visible": true
                                    },

                                    "lastValidated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastValidated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "mobilePhoneNumber": {
                                        "type": "String",
                                        "keyRaw": "mobilePhoneNumber",
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "visible": true
                                    },

                                    "phoneNumber": {
                                        "type": "String",
                                        "keyRaw": "phoneNumber",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "contactName": {
                            "type": "String",
                            "keyRaw": "contactName",
                            "visible": true
                        },

                        "companyName": {
                            "type": "String",
                            "keyRaw": "companyName",
                            "visible": true
                        },

                        "balance": {
                            "type": "String",
                            "keyRaw": "balance",
                            "visible": true
                        },

                        "billingAddress": {
                            "type": "Address",
                            "keyRaw": "billingAddress",

                            "selection": {
                                "fields": {
                                    "lastValidated": {
                                        "type": "DateTime",
                                        "keyRaw": "lastValidated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "address1": {
                                        "type": "String",
                                        "keyRaw": "address1",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "address2": {
                                        "type": "String",
                                        "keyRaw": "address2",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "county": {
                                        "type": "String",
                                        "keyRaw": "county",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "email": {
                                        "type": "String",
                                        "keyRaw": "email",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "phone": {
                                        "type": "String",
                                        "keyRaw": "phone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "email": {
                            "type": "String",
                            "keyRaw": "email",
                            "visible": true
                        },

                        "faxNumber": {
                            "type": "String",
                            "keyRaw": "faxNumber",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "mobilePhoneNumber": {
                            "type": "String",
                            "keyRaw": "mobilePhoneNumber",
                            "visible": true
                        },

                        "phoneNumber": {
                            "type": "String",
                            "keyRaw": "phoneNumber",
                            "visible": true
                        },

                        "salesPerson": {
                            "type": "UserAccount",
                            "keyRaw": "salesPerson",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Int",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "store": {
                            "type": "Store",
                            "keyRaw": "store",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "lastBillDate": {
                            "type": "DateTime",
                            "keyRaw": "lastBillDate",
                            "nullable": true,
                            "visible": true
                        },

                        "billDeliveryMethod": {
                            "type": "BillDeliveryMethod",
                            "keyRaw": "billDeliveryMethod",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "type": {
                            "type": "String",
                            "keyRaw": "type",
                            "visible": true
                        },

                        "driverLicenseNumber": {
                            "type": "String",
                            "keyRaw": "driverLicenseNumber",
                            "visible": true
                        },

                        "webAddress": {
                            "type": "String",
                            "keyRaw": "webAddress",
                            "visible": true
                        },

                        "dateEntered": {
                            "type": "DateTime",
                            "keyRaw": "dateEntered",
                            "nullable": true,
                            "visible": true
                        },

                        "notes": {
                            "type": "String",
                            "keyRaw": "notes",
                            "visible": true
                        },

                        "optionValues": {
                            "type": "CustomerOptionValue",
                            "keyRaw": "optionValues",

                            "selection": {
                                "fields": {
                                    "option": {
                                        "type": "CustomerOption",
                                        "keyRaw": "option",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "UInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "label": {
                                                    "type": "String",
                                                    "keyRaw": "label",
                                                    "visible": true
                                                },

                                                "showInCustomerList": {
                                                    "type": "Boolean",
                                                    "keyRaw": "showInCustomerList",
                                                    "visible": true
                                                },

                                                "dataType": {
                                                    "type": "CustomerOptionDataType",
                                                    "keyRaw": "dataType",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "attachments": {
                            "type": "CustomerAttachment",
                            "keyRaw": "attachments",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "public": {
                                        "type": "Boolean",
                                        "keyRaw": "public",
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "Int",
                                        "keyRaw": "rank",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "file": {
                                        "type": "File",
                                        "keyRaw": "file",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "Int",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "created": {
                                                    "type": "DateTime",
                                                    "keyRaw": "created",
                                                    "visible": true
                                                },

                                                "extension": {
                                                    "type": "String",
                                                    "keyRaw": "extension",
                                                    "visible": true
                                                },

                                                "mimetype": {
                                                    "type": "String",
                                                    "keyRaw": "mimetype",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "hash": {
                                                    "type": "String",
                                                    "keyRaw": "hash",
                                                    "visible": true
                                                },

                                                "type": {
                                                    "type": "FileType",
                                                    "keyRaw": "type",
                                                    "visible": true
                                                },

                                                "updated": {
                                                    "type": "Date",
                                                    "keyRaw": "updated",
                                                    "visible": true
                                                },

                                                "path": {
                                                    "type": "String",
                                                    "keyRaw": "path",
                                                    "visible": true
                                                },

                                                "size": {
                                                    "type": "Int",
                                                    "keyRaw": "size",
                                                    "visible": true
                                                },

                                                "host": {
                                                    "type": "String",
                                                    "keyRaw": "host",
                                                    "visible": true
                                                },

                                                "url": {
                                                    "type": "String",
                                                    "keyRaw": "url",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "acceptsBackOrders": {
                            "type": "Boolean",
                            "keyRaw": "acceptsBackOrders",
                            "visible": true
                        },

                        "acceptsPartialShipments": {
                            "type": "Boolean",
                            "keyRaw": "acceptsPartialShipments",
                            "visible": true
                        },

                        "accountLimit": {
                            "type": "Decimal2",
                            "keyRaw": "accountLimit",
                            "visible": true
                        },

                        "aging": {
                            "type": "AgingSchedule",
                            "keyRaw": "aging",

                            "selection": {
                                "fields": {
                                    "zeroToThirtyDays": {
                                        "type": "Decimal2",
                                        "keyRaw": "zeroToThirtyDays",
                                        "visible": true
                                    },

                                    "thirtyOneToSixtyDays": {
                                        "type": "Decimal2",
                                        "keyRaw": "thirtyOneToSixtyDays",
                                        "visible": true
                                    },

                                    "sixtyOneToNinetyDays": {
                                        "type": "Decimal2",
                                        "keyRaw": "sixtyOneToNinetyDays",
                                        "visible": true
                                    },

                                    "ninetyOneToOneHundredTwentyDays": {
                                        "type": "Decimal2",
                                        "keyRaw": "ninetyOneToOneHundredTwentyDays",
                                        "visible": true
                                    },

                                    "overOneHundredTwentyDays": {
                                        "type": "Decimal2",
                                        "keyRaw": "overOneHundredTwentyDays",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "allowedPaymentMethods": {
                            "type": "PaymentMethod",
                            "keyRaw": "allowedPaymentMethods",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "cashOnly": {
                            "type": "Boolean",
                            "keyRaw": "cashOnly",
                            "visible": true
                        },

                        "customLaborRate": {
                            "type": "Decimal2",
                            "keyRaw": "customLaborRate",
                            "visible": true
                        },

                        "laborRateType": {
                            "type": "LaborRateType",
                            "keyRaw": "laborRateType",
                            "visible": true
                        },

                        "defaultPaymentMethod": {
                            "type": "PaymentMethod",
                            "keyRaw": "defaultPaymentMethod",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultPricePercentage": {
                            "type": "Decimal2",
                            "keyRaw": "defaultPricePercentage",
                            "visible": true
                        },

                        "defaultPriceType": {
                            "type": "PriceType",
                            "keyRaw": "defaultPriceType",
                            "visible": true
                        },

                        "defaultTaxItem": {
                            "type": "TaxItem",
                            "keyRaw": "defaultTaxItem",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultTerm": {
                            "type": "Term",
                            "keyRaw": "defaultTerm",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "customerMemo": {
                                        "type": "String",
                                        "keyRaw": "customerMemo",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "requirePurchaseOrderNumber": {
                            "type": "Boolean",
                            "keyRaw": "requirePurchaseOrderNumber",
                            "visible": true
                        },

                        "taxExemptions": {
                            "type": "TaxExemption",
                            "keyRaw": "taxExemptions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "UInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "expirationDate": {
                                        "type": "Date",
                                        "keyRaw": "expirationDate",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "notes": {
                                        "type": "String",
                                        "keyRaw": "notes",
                                        "visible": true
                                    },

                                    "number": {
                                        "type": "String",
                                        "keyRaw": "number",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "blanketPurchaseOrderNumber": {
                            "type": "String",
                            "keyRaw": "blanketPurchaseOrderNumber",
                            "visible": true
                        },

                        "blanketPurchaseOrderNumberExpiration": {
                            "type": "Date",
                            "keyRaw": "blanketPurchaseOrderNumberExpiration",
                            "nullable": true,
                            "visible": true
                        },

                        "salesRegion": {
                            "type": "String",
                            "keyRaw": "salesRegion",
                            "nullable": true,
                            "visible": true
                        },

                        "storeRegion": {
                            "type": "String",
                            "keyRaw": "storeRegion",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "customerId": "UInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=e103c9377c24cfebe3de83abe837a0f986a5e1a3dea5000172797709a3c75132";