<script lang="ts">
	import type { State, SalesPerson, Store, CustomerAddressData } from 'utility/customer-helper'
	import { graphql } from '$houdini'
	import { createEventDispatcher } from 'svelte'
	import { getEventValue } from '@isoftdata/browser-event'

	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import TextArea from '@isoftdata/svelte-textarea'

	const dispatch = createEventDispatcher<{ addressChanged: { customerAddress: CustomerAddressData } }>()

	export let customerAddress: CustomerAddressData
	export let states: State[] = []
	export let stores: Store[] = []
	export let salesPersonList: SalesPerson[] = []
	export let showNotesField: boolean = false
	export let disableAllFields: boolean = false
	export let addressType: 'BILLING' | 'ALTERNATE' | 'VALIDATION' = 'BILLING'

	function onChange() {
		dispatch('addressChanged', { customerAddress })
	}

	async function lookupByZipcode(zipcode: string) {
		const { data } = await autofillByZipcodeQuery.fetch({
			variables: { zipcode },
		})

		if (data?.autofillByZip) {
			customerAddress.city = data.autofillByZip.city
			customerAddress.state = data.autofillByZip.stateAbbreviation
			customerAddress.country = data.autofillByZip.country
		}
	}

	const autofillByZipcodeQuery = graphql(`
		query AutofillByZip($zipcode: String) {
			autofillByZip(zipcode: $zipcode) {
				city
				country
				stateAbbreviation
			}
		}
	`)
</script>

<div class="form-row">
	<div class="col-12 col-md-6">
		<Input
			label="Company Name"
			bind:value={customerAddress.companyName}
			required={!customerAddress.contactName && addressType === 'BILLING'}
			validation={{
				validator: value => {
					if (!value && !customerAddress.contactName && addressType === 'BILLING') {
						return false
					}
					return true
				},
			}}
			disabled={disableAllFields || addressType === 'VALIDATION'}
			on:change={onChange}
		/>
	</div>
	<div class="col-12 col-md-6">
		<Input
			label="Contact Name"
			bind:value={customerAddress.contactName}
			required={!customerAddress.companyName && addressType === 'BILLING'}
			validation={{
				validator: value => {
					if (!value && !customerAddress.contactName && addressType === 'BILLING') {
						return false
					}
					return true
				},
			}}
			disabled={disableAllFields || addressType === 'VALIDATION'}
			on:change={onChange}
		/>
	</div>
	<div class="col-12 col-md-12">
		<TextArea
			label="Street Address"
			bind:value={customerAddress.street}
			disabled={disableAllFields}
			on:change={onChange}
		/>
	</div>
	<div class="col-12 col-md-4">
		<Input
			label="City"
			bind:value={customerAddress.city}
			disabled={disableAllFields}
			on:change={onChange}
		/>
	</div>
	<div class="col-12 col-md-2">
		<Select
			label="State"
			bind:value={customerAddress.state}
			disabled={disableAllFields}
			on:change={onChange}
		>
			{#each states as state}
				<option value={state.stateAbbreviation}>{state.stateAbbreviation}</option>
			{/each}
		</Select>
	</div>
	<div class="col-12 col-md-3">
		<Input
			label="Zip Code"
			placeholder="Autofills City, State, & Country"
			bind:value={customerAddress.zip}
			disabled={disableAllFields}
			on:change={() => {
				if (!(customerAddress.city || customerAddress.state || customerAddress.country) && customerAddress.zip) {
					lookupByZipcode(customerAddress.zip)
				}
				onChange()
			}}
		/>
	</div>
	<div class="col-12 col-md-3">
		<Input
			label="Country"
			bind:value={customerAddress.country}
			disabled={disableAllFields}
			on:change={onChange}
		/>
	</div>
	{#if addressType !== 'VALIDATION'}
		<hr class="w-100 mb-1" />
		<div class="col-12 col-md-6">
			<Input
				label="Email"
				bind:value={customerAddress.email}
				disabled={disableAllFields}
				on:change={onChange}
			/>
		</div>

		<div class="col-12 col-md-6">
			<Input
				label="Fax"
				bind:value={customerAddress.faxNumber}
				disabled={disableAllFields}
				on:change={onChange}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Phone Number"
				bind:value={customerAddress.phoneNumber}
				disabled={disableAllFields}
				on:change={onChange}
			/>
		</div>
		<div class="col-12 col-md-6">
			<Input
				label="Mobile Phone Number"
				bind:value={customerAddress.mobilePhoneNumber}
				disabled={disableAllFields}
				on:change={onChange}
			/>
		</div>
		<hr class="w-100 mb-1" />
		{#if addressType === 'BILLING'}
			<div class="col-12 col-md-6">
				<Input
					readonly
					label="Store Region"
					value={customerAddress.storeRegion}
					on:change={onChange}
				/>
			</div>
			<div class="col-12 col-md-6">
				<Input
					readonly
					label="Sales Region"
					value={customerAddress.salesRegion}
					on:change={onChange}
				/>
			</div>
		{:else if addressType === 'ALTERNATE'}
			<div class="col-12">
				<Input
					label="Department"
					value={customerAddress.department ?? ''}
					disabled={disableAllFields}
					on:change={event => {
						customerAddress.department = getEventValue(event)
						onChange()
					}}
				/>
			</div>
		{/if}
		<div class="col-12 col-md-6">
			<Select
				label="Store"
				emptyText="Use Billing Default"
				required={addressType === 'BILLING'}
				showEmptyOption={addressType === 'ALTERNATE'}
				value={customerAddress.store?.id ?? null}
				disabled={disableAllFields}
				on:change={event => {
					const value = getEventValue(event)
					if (value) {
						const id = parseInt(value, 10)
						customerAddress.store = stores.find(store => store.id === id) ?? null
					}
					onChange()
				}}
			>
				{#each stores as store}
					<option value={store.id}>{store.name}</option>
				{/each}
			</Select>
		</div>
		<div class="col-12 col-md-6">
			<Select
				label="Salesperson"
				emptyText="Use Billing Default"
				required={addressType === 'BILLING'}
				showEmptyOption={addressType === 'ALTERNATE'}
				value={customerAddress.salesPerson?.id ?? null}
				disabled={disableAllFields}
				on:change={event => {
					const value = getEventValue(event)
					if (value) {
						const id = parseInt(value, 10)
						customerAddress.salesPerson = salesPersonList.find(salesPerson => salesPerson.id === id) ?? null
					}
					onChange()
				}}
			>
				{#each salesPersonList as salesPerson}
					<option value={salesPerson.id}>{salesPerson.name}</option>
				{/each}
			</Select>
		</div>
		{#if showNotesField}
			<div class="col-12">
				<TextArea
					label="Notes"
					bind:value={customerAddress.notes}
					disabled={disableAllFields}
					on:change={onChange}
				/>
			</div>
		{/if}
	{/if}
</div>
