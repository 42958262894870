<script lang="ts">
	import type { InventoryTypesFull$result } from '$houdini'
	import type { Mediator } from 'types/common'
	import type { SvelteAsr } from 'types/common'
	import type { Writable } from 'type-fest'
	type InventoryType = Writable<InventoryTypesFull$result['inventoryTypes'][number]>

	import Modal from '@isoftdata/svelte-modal'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import { klona } from 'klona'
	import { graphql } from '$houdini'
	import { upsert } from '@isoftdata/utility-array'
	import { createEventDispatcher, getContext } from 'svelte'

	const mediator = getContext<Mediator>('mediator')

	export let asr: SvelteAsr
	export let inventoryTypeList: InventoryType[]
	export let showModal: boolean = false

	let inventoryType: InventoryType | undefined
	let title: string = ''
	let isNew: boolean = false
	let isSaving: boolean = false

	$: isDuplicate = !!inventoryTypeList.find(({ name, id }) => name.toUpperCase() === inventoryType?.name.toUpperCase() && id !== inventoryType?.id)
	$: isDuplicateId = !!inventoryTypeList.find(({ id }) => id === inventoryType?.id)

	const dispatch = createEventDispatcher<{
		inventoryTypeCreate: InventoryType
	}>()

	function newInventoryType() {
		const newInventoryTypeId =
			Math.max.apply(
				Math,
				inventoryTypeList.map(inventoryType => inventoryType.id),
			) + 1

		return {
			id: newInventoryTypeId,
			setId: newInventoryTypeId,
			name: '',
			vehicle: false,
			active: true,
			taxCode: '',
		}
	}

	export function add() {
		inventoryType = newInventoryType()
		title = 'New Inventory Type'
		showModal = true
		isNew = true
	}

	export function edit(inventoryTypeToEdit: InventoryType) {
		inventoryType = klona(inventoryTypeToEdit)
		title = 'Edit Inventory Type'
		showModal = true
		isNew = false
	}

	export async function save() {
		isSaving = true

		try {
			if (isNew && inventoryType) {
				await createMutation.mutate({ input: inventoryType })
				inventoryTypeList = upsert(inventoryTypeList, 'id', inventoryType)
				dispatch('inventoryTypeCreate', inventoryType)
				asr.go(null, { inventoryTypeId: inventoryType.id })
			} else if (inventoryType) {
				await updateMutation.mutate({ input: inventoryType })
				inventoryTypeList = upsert(inventoryTypeList, 'id', inventoryType)
			}
			showModal = false
		} catch (err: any) {
			mediator.publish('showMessage', {
				heading: 'Error Saving Inventory Type',
				message: err.message,
				type: 'danger',
				time: false,
			})
		} finally {
			isSaving = false
		}
	}
	//#region GraphQL
	const updateMutation = graphql(`
		mutation UpdateInventoryType($input: InventoryTypeUpdate!) {
			updateInventoryType(input: $input) {
				active
				id
				name
				setId
				vehicle
				taxCode
			}
		}
	`)

	const createMutation = graphql(`
		mutation CreateInventoryType($input: NewInventoryType!) {
			createInventoryType(input: $input) {
				active
				id
				name
				setId
				vehicle
				taxCode
			}
		}
	`)
	//#endregion
</script>

<Modal
	bind:show={showModal}
	{title}
	confirmButtonText="Save"
	confirmButtonIsLoading={isSaving}
	confirmButtonDisabled={isDuplicate || !inventoryType?.name || (isNew && isDuplicateId)}
	on:confirm={save}
	on:close={() => (showModal = false)}
>
	{#if inventoryType}
		<fieldset disabled={isSaving}>
			<div class="form-row">
				<div class="col-12 col-sm-6">
					<Input
						required
						label="Name"
						validation={{
							value: inventoryType.name,
							validator(value) {
								if (!value.trim()) {
									return false
								} else if (isDuplicate) {
									return 'Name already exists'
								}
								return true
							},
						}}
						bind:value={inventoryType.name}
					/>
				</div>
				<div class="col-12 col-sm-6">
					<Input
						required
						label="Type #"
						type="number"
						readonly={!isNew}
						validation={{
							value: inventoryType.id,
							validator(value) {
								if (!value) {
									return false
								} else if (isNew && isDuplicateId) {
									return 'Type # already exists'
								}
								return true
							},
						}}
						bind:value={inventoryType.id}
						on:change={() => {
							if (inventoryType) {
								inventoryType.setId = inventoryType.id
							}
						}}
					/>
				</div>
				<div class="col-12 col-sm-6">
					<Select
						label="Set #"
						bind:value={inventoryType.setId}
					>
						{#if isNew}
							<option value={inventoryType.id}>{inventoryType.name} - {inventoryType.id}</option>
						{/if}
						{#each inventoryTypeList as { id, name }}
							<option value={id}>{name} - {id}</option>
						{/each}
					</Select>
				</div>
				<div class="col-12 col-sm-6">
					<Input
						label="Tax Code"
						placeholder="N/A"
						bind:value={inventoryType.taxCode}
					/>
				</div>
			</div>

			<div class="form-row mt-2">
				<div class="col-6 col-sm-4">
					<Checkbox
						label="Active"
						bind:checked={inventoryType.active}
					/>
					<Checkbox
						label="Vehicle Unit"
						bind:checked={inventoryType.vehicle}
					/>
				</div>
			</div>
		</fieldset>
	{/if}
</Modal>
