<script
	lang="ts"
	context="module"
>
	export type Type = 'ASSEMBLY' | 'PART' | 'VEHICLE'

	//end of module section
</script>

<script lang="ts">
	import type { Manufacturer, InventoryType } from 'utility/search-fields'

	import { startsWithVowel } from 'utility/language-tools'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import { loadPartManufacturersForInventoryType, loadVehicleManufacturers } from 'utility/search-fields'

	export let manufacturer: Manufacturer | null = null
	export let inventoryType: InventoryType | null | undefined = undefined
	export let disabled: boolean = false
	export let type: Type // No default value here, require the consumer to pass the type of manufacturer.

	let manufacturerList: Promise<Manufacturer[]> | Manufacturer[] = []

	async function loadManufacturers(type: Type, inventoryType?: InventoryType | null) {
		if (inventoryType) {
			manufacturerList = loadPartManufacturersForInventoryType(type === 'ASSEMBLY' ? (inventoryType?.setId ?? inventoryType?.id) : inventoryType?.id)
			manufacturerList.then(loadedManufacturers => {
				manufacturerList = loadedManufacturers
				// If we have a selected manufacturer but after loading the new list, it's not in the list, then we need to the clear the selection
				if (manufacturer && !loadedManufacturers.find(loadedManufacturer => loadedManufacturer.id === manufacturer?.id)) {
					manufacturer = null
				}
			})
		} else if (type === 'VEHICLE') {
			manufacturerList = loadVehicleManufacturers()
			manufacturer = null
		} else {
			manufacturerList = []
			manufacturer = null
		}
	}

	function computePlaceholder(type: Type) {
		const typeLabel = typeLabelMap.get(type)
		if (typeLabel) {
			return `Select ${startsWithVowel(typeLabel) ? 'an' : 'a'} ${typeLabel}`
		} else return 'Select a Manufacturer'
	}

	const typeLabelMap = new Map<Type, string>([
		['ASSEMBLY', 'Assembly Manufacturer'],
		['PART', 'Part Manufacturer'],
		['VEHICLE', 'Vehicle Make'],
	])

	$: loadManufacturers(type, inventoryType)
	$: computedPlaceholder = computePlaceholder(type)
	$: label = typeLabelMap.get(type) ?? 'Manufacturer'
</script>

<Autocomplete
	{label}
	getLabel={option => option?.name ?? ''}
	options={manufacturerList}
	bind:value={manufacturer}
	emptyValue={null}
	placeholder={computedPlaceholder}
	disabled={disabled || (type !== 'VEHICLE' && !inventoryType)}
	on:change
	{...$$restProps}
>
	<svelte:fragment slot="hint">
		<slot name="hint"></slot>
	</svelte:fragment>
</Autocomplete>
