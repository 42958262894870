export default {
    "name": "UpdateCustomer",
    "kind": "HoudiniMutation",
    "hash": "f077088bd02f50f902306a601fabccd87e9017723dfb18170f1cc92bab4c9468",

    "raw": `mutation UpdateCustomer($input: CustomerUpdate!) {
  updateCustomer(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateCustomer": {
                "type": "Customer",
                "keyRaw": "updateCustomer(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "CustomerUpdate"
        },

        "types": {
            "NewCustomerAddress": {
                "city": "String",
                "companyName": "String",
                "contactName": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "faxNumber": "String",
                "label": "String",
                "mobilePhoneNumber": "String",
                "notes": "String",
                "phoneNumber": "String",
                "primary": "Boolean",
                "salesPersonId": "Int",
                "state": "String",
                "storeId": "Int",
                "street": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "CustomerAddressUpdate": {
                "city": "String",
                "companyName": "String",
                "contactName": "String",
                "country": "String",
                "department": "String",
                "email": "String",
                "faxNumber": "String",
                "id": "UInt",
                "label": "String",
                "mobilePhoneNumber": "String",
                "notes": "String",
                "phoneNumber": "String",
                "primary": "Boolean",
                "salesPersonId": "Int",
                "state": "String",
                "storeId": "Int",
                "street": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "CustomerAddressesUpdate": {
                "create": "NewCustomerAddress",
                "remove": "UInt",
                "update": "CustomerAddressUpdate"
            },

            "CustomerAllowedPaymentMethodsUpdate": {
                "create": "Int",
                "remove": "Int"
            },

            "NewCustomerAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "CustomerAttachmentUpdate": {
                "id": "Int",
                "public": "Boolean",
                "rank": "Int"
            },

            "CustomerAttachmentsUpdate": {
                "create": "NewCustomerAttachment",
                "remove": "Int",
                "update": "CustomerAttachmentUpdate"
            },

            "AddressInput": {
                "address": "String",
                "city": "String",
                "country": "String",
                "state": "String",
                "validated": "Boolean",
                "zip": "String"
            },

            "NestedCustomerOptionValueUpdate": {
                "optionId": "Int",
                "value": "String"
            },

            "NewTaxExemption": {
                "expiration": "Date",
                "notes": "String",
                "number": "String",
                "state": "String"
            },

            "TaxExemptionUpdate": {
                "expiration": "Date",
                "id": "UInt",
                "notes": "String",
                "number": "String",
                "state": "String"
            },

            "TaxExemptionsUpdate": {
                "create": "NewTaxExemption",
                "remove": "UInt",
                "update": "TaxExemptionUpdate"
            },

            "CustomerUpdate": {
                "acceptsBackOrders": "Boolean",
                "acceptsPartialShipments": "Boolean",
                "accountLimit": "Decimal2",
                "active": "Boolean",
                "addresses": "CustomerAddressesUpdate",
                "allowedPaymentMethods": "CustomerAllowedPaymentMethodsUpdate",
                "attachments": "CustomerAttachmentsUpdate",
                "billDeliveryMethod": "BillDeliveryMethod",
                "billingAddress": "AddressInput",
                "blanketPurchaseOrderNumber": "String",
                "blanketPurchaseOrderNumberExpiration": "Date",
                "cashOnly": "Boolean",
                "companyName": "String",
                "contactName": "String",
                "customLaborRate": "Decimal2",
                "defaultPaymentMethodId": "UInt",
                "defaultPricePercentage": "Decimal2",
                "defaultPriceType": "PriceType",
                "defaultTaxItemId": "UInt",
                "defaultTermId": "UInt",
                "driverLicenseNumber": "String",
                "dunsNumber": "Int",
                "email": "String",
                "faxNumber": "String",
                "id": "UInt",
                "laborRateType": "LaborRateType",
                "mobilePhoneNumber": "String",
                "notes": "String",
                "optionValues": "NestedCustomerOptionValueUpdate",
                "phoneNumber": "String",
                "requirePurchaseOrderNumber": "Boolean",
                "salespersonId": "UInt",
                "storeId": "UInt",
                "taxExemptions": "TaxExemptionsUpdate",
                "type": "String",
                "webAddress": "String",
                "webUserId": "Int"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=a4a41def15fd28a3b09c362aaa22e672ae3744f870a1b9fef08837aa5ae6a443";