<script lang="ts">
	import type { Mediator } from 'types/common'

	import LoadItemModal from '@isoftdata/svelte-load-item-modal'

	import { CustomerSearchStore, type CustomerSearch$result } from '$houdini'
	import { getContext } from 'svelte'

	type CustomerResult = CustomerSearch$result['customers']['items'][number]

	export let chooseItem: (item: CustomerResult) => void | Promise<void>

	let loadCustomerModal: LoadItemModal<CustomerResult>

	const mediator = getContext<Mediator>('mediator')

	const query = new CustomerSearchStore()

	export function show() {
		loadCustomerModal?.show()
	}
</script>

<LoadItemModal
	itemTitle="Customers"
	itemIdProp="id"
	itemDisplayProp="companyName"
	doSearch={async searchString => {
		const { data } = await query.fetch({
			variables: {
				filter: {
					keyword: searchString,
				},
			},
		})
		// TODO remove when the API gets this filter
		return data?.customers.items?.filter(item => item.active) ?? []
	}}
	on:lookupError={({ detail: { error } }) =>
		mediator.publish('showMessage', {
			heading: 'Error Fetching Customers',
			message: error.message,
			type: 'danger',
			time: false,
		})}
	on:chooseItem={({ detail: { item } }) => chooseItem(item)}
	bind:this={loadCustomerModal}
	let:item
>
	<div class="w-100 d-flex justify-content-between">
		<h5 class="mb-1">{item.companyName ?? item.contactName}</h5>
		<h5 class="mb-1">{item.phoneNumber}</h5>
	</div>

	{#if item.companyName && item.contactName}
		<h5 class="mb-1">{item.contactName}</h5>
	{/if}
	<div class="w-100 d-flex justify-content-between">
		<div class="mb-1">
			{item.billingAddress?.address1}
		</div>
		<div class="mb-1">
			{item.billingAddress?.city}{item.billingAddress.city && item.billingAddress.state ? ', ' : ''}{item.billingAddress?.state}
			{item.billingAddress?.zip}
		</div>
	</div>
</LoadItemModal>
