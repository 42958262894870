export default {
    "name": "AddressSearch",
    "kind": "HoudiniQuery",
    "hash": "ae147f6c031d724c2a303d4a67a924350391278eae066fd5f9a6054147c70829",

    "raw": `query AddressSearch($input: AddressSearchInput!) {
  addressSearch(input: $input) {
    message
    status
    matches {
      address1
      city
      country
      plus4
      state
      zip
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "addressSearch": {
                "type": "AddressSearchOutput",
                "keyRaw": "addressSearch(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "message": {
                            "type": "String",
                            "keyRaw": "message",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "AddressSearchStatusCodeEnum",
                            "keyRaw": "status",
                            "nullable": true,
                            "visible": true
                        },

                        "matches": {
                            "type": "Address",
                            "keyRaw": "matches",

                            "selection": {
                                "fields": {
                                    "address1": {
                                        "type": "String",
                                        "keyRaw": "address1",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "plus4": {
                                        "type": "String",
                                        "keyRaw": "plus4",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "AddressSearchInput"
        },

        "types": {
            "AddressSearchInput": {
                "address1": "String",
                "address2": "String",
                "city": "String",
                "country": "String",
                "plus4": "String",
                "state": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5afa2549998cf3fd74e5c8b466fb80694704b21180bc8ea2d7d8b16b208f04f4";