<script lang="ts">
	import type { CustomerAddressData, State } from 'utility/customer-helper'
	import { getEventChecked } from '@isoftdata/browser-event'
	import { createEventDispatcher } from 'svelte'
	import { graphql, type AddressSearchStatusCodeEnum$options as AddressValidationStatusCode } from '$houdini'

	import AddressFields from './AddressFields.svelte'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Icon from '@isoftdata/svelte-icon'
	import Modal from '@isoftdata/svelte-modal'
	import Table, { type Column, Td } from '@isoftdata/svelte-table'

	type ValidationResult = {
		address: string | null
		city: string | null
		state: string | null
		zip: string | null
		country: string | null
	}

	const dispatch = createEventDispatcher<{ selectAddressData: { addressData: CustomerAddressData } }>()
	const validationStatusMessageMap: Record<AddressValidationStatusCode, { message: string; alertColor: string }> = {
		None: { message: 'No matches found', alertColor: 'danger' },
		Partial: { message: 'Partial matches found', alertColor: 'primary' },
		Verified: { message: 'Address verified', alertColor: 'success' },
	}
	const columns: Column[] = [
		{ property: 'selected', name: 'Use', align: 'center', width: '1rem' },
		{ property: 'address', name: 'Address' },
		{ property: 'city', name: 'City' },
		{ property: 'state', name: 'State' },
		{ property: 'zip', name: 'Zip' },
		{ property: 'country', name: 'Country' },
	]

	export let states: State[] = []

	let show = false
	let addressData: CustomerAddressData
	let validationResults: ValidationResult[] = []
	let validationError: string | null = null
	let validationMessage: AddressValidationStatusCode | null = null
	let isValidating = false
	let addressChanged = false
	let selectedAddressMatch: ValidationResult | null = null

	export async function open(customerAddress: CustomerAddressData) {
		addressData = customerAddress
		show = true
		await validateAddress()
	}

	function close() {
		show = false
		addressData = {
			...addressData,
			street: '',
			city: '',
			state: '',
			zip: '',
			country: '',
		}
		selectedAddressMatch = null
		validationResults = []
		validationError = null
		validationMessage = null
		isValidating = false
		addressChanged = false
	}

	async function validateAddress() {
		validationError = null
		isValidating = true
		try {
			const { data } = await addressSearchQuery.fetch({
				variables: {
					input: {
						address1: addressData.street,
						city: addressData.city,
						state: addressData.state,
						zip: addressData.zip,
						country: addressData.country,
					},
				},
			})
			if (data?.addressSearch) {
				validationMessage = data.addressSearch.status
				validationResults = data.addressSearch.matches.reduce((acc: ValidationResult[], match) => {
					if (!match) {
						return acc
					}
					acc.push({
						address: match.address1,
						city: match.city,
						state: match.state,
						zip: match.zip,
						country: match.country,
					})
					return acc
				}, [])
			}
		} catch (error: any) {
			validationError = error.message
		}
		isValidating = false
		addressChanged = false
	}

	const addressSearchQuery = graphql(`
		query AddressSearch($input: AddressSearchInput!) {
			addressSearch(input: $input) {
				message
				status
				matches {
					address1
					city
					country
					plus4
					state
					zip
				}
			}
		}
	`)
</script>

<Modal
	bind:show
	title="Validate Address"
	modalSize="xl"
	backdropClickCancels={false}
	confirmButtonDisabled={isValidating || !selectedAddressMatch || selectedAddressMatch === null}
	on:close={close}
	on:confirm={() => {
		dispatch('selectAddressData', { addressData })
		show = false
	}}
>
	<div class="form-row">
		<div class="col-12 col-md-5">
			<div class="card">
				<div class="card-header">
					<h5 class="mb-0">Address</h5>
				</div>
				<div class="card-body">
					<form
						id="address-form"
						on:submit|preventDefault={() => {
							validateAddress()
						}}
					>
						<AddressFields
							bind:customerAddress={addressData}
							{states}
							disableAllFields={false}
							addressType="VALIDATION"
							on:addressChanged={() => (addressChanged = true)}
						/>
					</form>
				</div>
				<div class="card-footer d-flex justify-content-between">
					<Button
						outline
						type="submit"
						form="address-form"
						size="sm"
						color="primary"
						iconClass="magnifying-glass"
						disabled={!addressChanged}
						isLoading={isValidating}
					>
						Validate
					</Button>
					<Button
						outline
						size="sm"
						color="danger"
						iconClass="broom"
						on:click={() => {
							addressData = {
								...addressData,
								street: '',
								city: '',
								state: '',
								zip: '',
								country: '',
							}
							selectedAddressMatch = null
						}}
					>
						Clear
					</Button>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-7">
			<div class="card h-100">
				<div class="card-header">
					<h5 class="mb-0">Matches</h5>
				</div>
				<div class="card-body">
					{#if isValidating}
						<div class="text-center">
							<Icon
								icon="spinner"
								class="fa-spin"
								size="xl"
							/>
						</div>
					{:else if validationError}
						<div
							class="alert alert-danger"
							role="alert"
						>
							{validationError}
						</div>
					{:else}
						<Table
							responsive
							{columns}
							rows={validationResults}
							let:row
						>
							<svelte:fragment slot="no-rows">
								<tr>
									<td
										colspan={columns.length}
										class="text-center">No results found</td
									>
								</tr>
							</svelte:fragment>
							<tr class:table-primary={row === selectedAddressMatch}>
								<Td property="selected">
									<Checkbox
										checked={row === selectedAddressMatch}
										showLabel={false}
										on:change={event => {
											const value = getEventChecked(event)
											if (value) {
												selectedAddressMatch = row
												addressData = {
													...addressData,
													street: row.address,
													city: row.city,
													state: row.state,
													zip: row.zip,
													country: row.country,
													validated: true,
												}
											}
										}}
									/>
								</Td>
								<Td property="address">{row.address}</Td>
								<Td property="city">{row.city}</Td>
								<Td property="state">{row.state}</Td>
								<Td property="zip">{row.zip}</Td>
								<Td property="country">{row.country}</Td>
							</tr>
						</Table>
						{#if validationMessage && validationResults.length > 0}
							<div
								class="alert alert-{validationStatusMessageMap[validationMessage].alertColor} mt-2"
								role="alert"
							>
								{validationStatusMessageMap[validationMessage].message}
							</div>
						{/if}
					{/if}
				</div>
			</div>
		</div>
	</div>
</Modal>
