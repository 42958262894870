<script lang="ts">
	import type { Category, InventoryType, SearchField } from 'utility/search-fields'

	import CategoryAutocomplete from './CategoryAutocomplete.svelte'
	import EditModeControls from './EditModeControls.svelte'
	import Pin from './Pin.svelte'

	export let searchField: SearchField
	export let category: Category | null = null
	export let inventoryType: InventoryType | null
	export let inEditMode: boolean
	export let pinnedSearchFields: Set<SearchField['key']> //Required
	export let selectedSearchFieldsKeys: Set<SearchField['key']> //Required
	export let disabled: boolean = false
</script>

<EditModeControls
	{searchField}
	{inEditMode}
	bind:pinnedSearchFields
	bind:selectedSearchFieldsKeys
>
	<CategoryAutocomplete
		{inventoryType}
		bind:category
		disabled={inEditMode || disabled}
		getLabel={option => option?.name ?? ''}
		{...$$restProps}
		on:change
	>
		<svelte:fragment slot="hint">
			<Pin isPinned={pinnedSearchFields.has(searchField.key)} />
		</svelte:fragment>
	</CategoryAutocomplete>
</EditModeControls>
